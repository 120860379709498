import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchDashboardBikes = createAsyncThunk(
  'fetchDashboardBikes',
  async ({ params }) => {
    const response = await axiosClient.get(`/dashboard/bikes/`, {
      params
    })
    return response?.data?.results || []
  }
)

const fetchDashboardBike = createAsyncThunk(
  'fetchDashboardBike',
  async ({ params }) => {
    const response = await axiosClient.get(`/dashboard/metrics/`, {
      params
    })
    return response?.data
  }
)

const fetchMetrics = createAsyncThunk(
  'fetchMetrics',
  async ({ params }) => {
    const response = await axiosClient.get(`/dashboard/metrics/`, {
      params
    })

    return response?.data
  }
)

const fetchPastMetrics = createAsyncThunk(
  'fetchPastMetrics',
  async ({ params }) => {
    const response = await axiosClient.get(`/dashboard/metrics/`, {
      params
    })

    return response?.data
  }
)

const fetchClientCount = createAsyncThunk(
  'fetchClientCount',
  async () => {
    const response = await axiosClient.get(`/dashboard/bikes/count/client/`)

    return response?.data
  }
)

const fetchSubscriptionCount = createAsyncThunk(
  'fetchSubscriptionCount',
  async () => {
    const response = await axiosClient.get(`/dashboard/bikes/count/subscription/`)

    return response?.data
  }
)

const initialState = {
  dashboardBikes: [],
  dashboardBike: null,
  metrics: {},
  pastMetrics: {},
  clientCount: {},
  subscriptionCount: {}
}

export const slice = createSlice({
  name: 'dashboard',

  initialState,

  reducers: {
    setDashboardBike(state, { payload }){
      state.dashboardBike = payload
    },
  },

  extraReducers: (builder) => {
    
    builder.addCase(fetchDashboardBikes.fulfilled, (state, { payload }) => {
      state.dashboardBikes = payload
    })

    builder.addCase(fetchDashboardBike.fulfilled, (state, { payload }) => {
      state.dashboardBike = payload
    })
    
    builder.addCase(fetchMetrics.fulfilled, (state, { payload }) => {
      state.metrics = payload
    })

    builder.addCase(fetchPastMetrics.fulfilled, (state, { payload }) => {
      state.pastMetrics = payload
    })

    builder.addCase(fetchMetrics.pending, (state) => {
      state.metrics = {}
    })

    builder.addCase(fetchPastMetrics.pending, (state) => {
      state.pastMetrics = {}
    })

    builder.addCase(fetchClientCount.fulfilled, (state, { payload }) => {
      state.clientCount = payload 
    })

    builder.addCase(fetchSubscriptionCount.fulfilled, (state, { payload }) => {
      state.subscriptionCount = payload
    })
  }
})

const {
  setDashboardBike
} = slice.actions

export {
  fetchDashboardBikes,
  fetchMetrics,
  fetchDashboardBike,
  fetchPastMetrics,
  setDashboardBike,
  fetchSubscriptionCount,
  fetchClientCount
}

export default slice.reducer;
