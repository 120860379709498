import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from "react-redux"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import countries from "i18n-iso-countries"

import { store } from './store'

import './18n'
import '../src/assets/styles/reset.css' 
import '../src/assets/styles/fonts.css' 
import '../src/assets/styles/global.css' 
import 'react-image-gallery/styles/css/image-gallery.css'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer/>
      <App />
    </Provider>
  </React.StrictMode>
);
