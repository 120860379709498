import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

import { fetchDashboardBike, setDashboardBike } from '../../store/slices/dashboard/slice'
import {
   Text,
   LoaderIcon,
   StatusCircle,
   RightArrowIcon,
   Button
} from '../../components'
import {
   colorForFilters,
   colorsForCharge,
   getChargeStatus,
   ChargeIconByCharge
} from '../../core'
import {
  BikeModalHeader,
  BikeModal
} from './styled'

const MapBikeModal = ({ onClose, bike, cliensFilter, plansFilter, dateFilter }) => {
  const { id } = bike
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dashboardBike } = useSelector(state => state.dashboard)

  useEffect(() => {
    if(id){
      dispatch(fetchDashboardBike({ params: { bike_id: id }}))
    }

    return () => dispatch(setDashboardBike(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <BikeModal>
      {dashboardBike
        ? (
          <>
            <BikeModalHeader>
              <Grid container rowSpacing={0} columnSpacing={1} justifyContent="space-between">
                <Grid item xs="auto">
                  <Text fontWeight="600" fontSize="16px">{bike?.uid}</Text>
                </Grid>
                <Grid item xs="auto" onClick={onClose}>
                  <CloseIcon fontSize='small' sx={{ cursor: 'pointer' }}/>
                </Grid>
              </Grid> 
              <Grid container rowSpacing={0} columnSpacing={1} justifyContent="space-between">
                {
                  bike?.status && (
                    <Grid item xs="auto">
                      <StatusCircle background={colorForFilters.status[bike?.status]}>
                          {t(`units.statuses.${bike?.status}`)}
                      </StatusCircle>
                    </Grid>
                  )
                }
                <Grid item xs="auto">
                  <Text style={{ alignItems: 'center' }} color={colorsForCharge[getChargeStatus(bike?.charge)]}>
                    {ChargeIconByCharge[getChargeStatus(bike?.charge)]}&nbsp;
                    {getChargeStatus(bike?.charge) !== "MISSING" ? `${Number(bike?.charge).toFixed(2)}%` : '—'}
                  </Text>
                </Grid>
              </Grid> 
            </BikeModalHeader>
            <Grid container rowSpacing={0} columnSpacing={2}>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.plan')}</Text>
                <Text fontSize="14px" fontWeight="600">{t(`units.subscriptions.${bike?.subscription_type}`)}</Text>
                {
                  bike?.rta_plate_number && (
                    <Text fontSize="12px" fontWeight="400">{bike?.rta_plate_number}</Text>
                  )
                }   
              </Grid>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.model')}</Text>
                <Text fontSize="14px" fontWeight="600">{bike?.bike_model.name}</Text>
              </Grid>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D" uppercase>{t('metrics.mileage')}</Text>
                <Text fontSize="14px" fontWeight="600" style={{ alignItems: 'center'}}>
                  <NumberFormat value={Math.round(dashboardBike?.mileage / 1000)} displayType={'text'} thousandSeparator=" " />&nbsp;
                  <Text fontSize="12px" fontWeight="600" color="#7E8B99">km</Text>
                </Text>
                
              </Grid>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D" uppercase>{t('metrics.utilization')}</Text>
                <Text fontSize="14px" fontWeight="600" style={{ alignItems: 'center'}}>
                  {dashboardBike?.utilization}&nbsp;
                  <Text fontSize="12px" fontWeight="600" color="#7E8B99">%</Text>
                </Text>
              </Grid>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D" uppercase>{t('metrics.downtime')}</Text>
                <Text fontSize="14px" fontWeight="600" style={{ alignItems: 'center'}}>
                  <NumberFormat value={Math.round(dashboardBike?.downtime / 3600)} displayType={'text'} thousandSeparator=" " />&nbsp;
                  <Text fontSize="12px" fontWeight="600" color="#7E8B99">h</Text>
                </Text>
                
              </Grid>
              <Grid xs={6} item marginBottom={2} display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D" uppercase>{t('metrics.breakdowns')}</Text>
                <Text fontSize="14px" fontWeight="600">
                  <NumberFormat value={dashboardBike?.breakdowns} displayType={'text'} thousandSeparator=" " />
                </Text>
                
              </Grid>
              <Grid xs={12} item display="flex" flexDirection="column">
                <Button
                  size="small"
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate(`/unit/${id}/details`, { state: { backpath: '/dashboard', cliensFilter, plansFilter, dateFilter }})}
                >
                  <Text margin="2px 15px 0 0" fontWeight="500" color="#4A545E">More info</Text>
                  <RightArrowIcon/>
                </Button>
              </Grid>
            </Grid>
          </>
        )
        : (
          <LoaderIcon style={{ margin: 'auto' }} />
        )
      }
    </BikeModal>
  )
}

export default MapBikeModal