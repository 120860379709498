import React from 'react'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types';

import { 
  DrawnerBody,
 } from './styled'
 import DrawnerUnit from './drawner-unit'

const Drawner = ({ flyToMarker }) => {
  const { 
    fleetMap: {
      drawnerOpen,
      drawerItems
    },
   } = useSelector(state => state)

  if(!drawnerOpen || !drawerItems.length){
    return null;
  }

  return (
    <DrawnerBody>
      {drawerItems.length
      ? drawerItems.map((item, index) => 
        <DrawnerUnit
          flyToMarker={() => flyToMarker({ lat: item.location.lat , lon: item.location.lon, zoom: 17 })}
          key={item.id}
          uid={item.uid}
          charge={item.charge}
          status={item.status}
          subscriptionType={item.subscription_type}
          rtaPlateNumber={item.rta_plate_number}
          modelName={item.bike_model.name}
          isLast={drawerItems.length - 1 === index}
          clickable
        />
      )
      : null
    }
    </DrawnerBody>
  )
}

Drawner.propTypes = {
  flyToMarker: PropTypes.func,
}

export default Drawner