import React from 'react'

const VideoIcon = () => {
    return (
			<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.300049 2.79998C0.300049 1.58495 1.28502 0.599976 2.50005 0.599976H12.5C13.7151 0.599976 14.7001 1.58495 14.7001 2.79998V11.2C14.7001 12.415 13.7151 13.4 12.5 13.4H2.50005C1.28502 13.4 0.300049 12.415 0.300049 11.2V2.79998ZM1.50005 4.79998V6.39998H3.50005V4.79998H1.50005ZM3.50005 3.59998H1.50005V2.79998C1.50005 2.24769 1.94776 1.79998 2.50005 1.79998H3.50005V3.59998ZM4.70005 12.2H10.3V1.79998H4.70005V12.2ZM11.5 1.79998V3.59998H13.5V2.79998C13.5 2.24769 13.0523 1.79998 12.5 1.79998H11.5ZM13.5 4.79998H11.5V6.39998H13.5V4.79998ZM13.5 7.59998H11.5V9.19998H13.5V7.59998ZM13.5 10.4H11.5V12.2H12.5C13.0523 12.2 13.5 11.7523 13.5 11.2V10.4ZM3.50005 12.2V10.4H1.50005V11.2C1.50005 11.7523 1.94776 12.2 2.50005 12.2H3.50005ZM1.50005 9.19998H3.50005V7.59998H1.50005V9.19998Z" fill="#555F6D"/>
      </svg>

    )
}

export default VideoIcon;