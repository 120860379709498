import React from 'react'

const PlusIcon = () => {
    return (
			<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 0.000488281C6.91421 0.000488281 7.25 0.336275 7.25 0.750488V5.25061H11.75C12.1642 5.25061 12.5 5.5864 12.5 6.00061C12.5 6.41482 12.1642 6.75061 11.75 6.75061H7.25V11.2505C7.25 11.6647 6.91421 12.0005 6.5 12.0005C6.08579 12.0005 5.75 11.6647 5.75 11.2505V6.75061H1.25C0.835786 6.75061 0.5 6.41482 0.5 6.00061C0.5 5.5864 0.835786 5.25061 1.25 5.25061H5.75V0.750488C5.75 0.336275 6.08579 0.000488281 6.5 0.000488281Z" fill="#7E8B99"/>
      </svg>

    )
}

export default PlusIcon;