
 export const getMediaUrl = () => {
  const baseUrl = process.env.REACT_APP_MEDIA_URL
  // проверяем на то, что путь в .env абсолютный или нет
  if(baseUrl[0] === 'h'){
    return baseUrl
  }
  const loc = window.location;

  return `${loc.protocol}//${loc.host}${baseUrl}`
}