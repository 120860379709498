import React from 'react'

const MapExpandIcon = () => {
    return (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.75 1.5C2.05964 1.5 1.5 2.05964 1.5 2.75V5.25C1.5 5.66421 1.16421 6 0.75 6C0.335786 6 0 5.66421 0 5.25V2.75C0 1.23122 1.23122 0 2.75 0H5.25C5.66421 0 6 0.335786 6 0.75C6 1.16421 5.66421 1.5 5.25 1.5H2.75ZM12 0.75C12 0.335786 12.3358 0 12.75 0H15.25C16.7688 0 18 1.23122 18 2.75V5.25C18 5.66421 17.6642 6 17.25 6C16.8358 6 16.5 5.66421 16.5 5.25V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5H12.75C12.3358 1.5 12 1.16421 12 0.75ZM0.75 12C1.16421 12 1.5 12.3358 1.5 12.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H5.25C5.66421 16.5 6 16.8358 6 17.25C6 17.6642 5.66421 18 5.25 18H2.75C1.23122 18 0 16.7688 0 15.25V12.75C0 12.3358 0.335786 12 0.75 12ZM17.25 12C17.6642 12 18 12.3358 18 12.75V15.25C18 16.7688 16.7688 18 15.25 18H12.75C12.3358 18 12 17.6642 12 17.25C12 16.8358 12.3358 16.5 12.75 16.5H15.25C15.9404 16.5 16.5 15.9404 16.5 15.25V12.75C16.5 12.3358 16.8358 12 17.25 12Z" fill="#4A545E"/>
      </svg>

    )
}

export default MapExpandIcon;