import { getMediaUrl } from ".."
 
 
 export const normalizeAttachments = (attachments) => {
  if(!attachments?.length){
    return null
  }
  return attachments.reduce((acc, item) => {
    if(acc[item.type]){
      acc[item.type].push(`${getMediaUrl()}${item.url}`)
    } else {
      acc[item.type] = [`${getMediaUrl()}${item.url}`]
    }

    return acc
  }, {})
}