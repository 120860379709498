export const prepQuery = (query) => {
  return Object.keys(query).reduce((acc, key) => {
    const value = query[key]
    if(!value){
      return acc
    }
    return {
      ...acc,
      [key]: Array.isArray(value) ? value.join(',') : value
    }
  }, {})
}