import { styled } from '@mui/material/styles'

export const AddButton = styled('label')`
  padding: 16px;
  border: 2px dashed #CFD6DD;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: ${props => props.height || '56px'};
`