import React from 'react'
import { PropTypes } from 'prop-types';

import { TextComponent } from './styled'

const Text = ({ children, ...props}) => {
  return <TextComponent {...props}>
            {children}
         </TextComponent>
}

Text.propTypes = {
  nowrap: PropTypes.bool,
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  uppercase: PropTypes.bool,
  fontFamily: PropTypes.string,
  display: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  ellipsis: PropTypes.bool,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Text