import React from 'react'

function MapMinusIcon() {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8332 1.83317H0.166504V0.166504H11.8332V1.83317Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MapMinusIcon
