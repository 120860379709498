import { configureStore } from '@reduxjs/toolkit'


import * as slices from './slices'

export const store = configureStore({ reducer: {
    global: slices.global,
    fleetMap: slices.fleetMap,
    fleetList: slices.fleetList,
    batteries: slices.batteries,
    requests: slices.requests,
    clients: slices.clients,
    users: slices.users,
    bikes: slices.bikes,
    ownerships: slices.ownerships,
    companies: slices.companies,
    countries: slices.countries,
    dashboard: slices.dashboard,
}})

