import React from 'react'
import { PropTypes } from 'prop-types';

const MapMarkerIcon = ({ color }) => {
    return (
			<svg style={{ cursor: 'pointer '}} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_109_51956)">
        <rect x="13" y="13" width="8" height="8" rx="4" fill="white"/>
        <rect x="11.5" y="11.5" width="11" height="11" rx="5.5" stroke={color} strokeWidth="3"/>
        </g>
        <defs>
        <filter id="filter0_d_109_51956" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_51956"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_51956" result="shape"/>
        </filter>
        </defs>
      </svg>


    )
}

MapMarkerIcon.propTypes = {
  color: PropTypes.string
}

export default MapMarkerIcon;