import React from 'react'
import { Outlet } from "react-router-dom";
import Sidebar from '../sidebar'
import { Wrapper } from './styled'

const Layout = ({ children }) => {
	return (
		<Wrapper>
			<Sidebar/>
			<Outlet/>
			{children}
		</Wrapper>
	)
}

export default Layout