
import React from 'react'

const DashboardIcon = () => {
    return (
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33333 8.83333H6.33333C6.79167 8.83333 7.16667 8.45833 7.16667 8V1.33333C7.16667 0.875 6.79167 0.5 6.33333 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333V8C0.5 8.45833 0.875 8.83333 1.33333 8.83333ZM1.33333 15.5H6.33333C6.79167 15.5 7.16667 15.125 7.16667 14.6667V11.3333C7.16667 10.875 6.79167 10.5 6.33333 10.5H1.33333C0.875 10.5 0.5 10.875 0.5 11.3333V14.6667C0.5 15.125 0.875 15.5 1.33333 15.5ZM9.66667 15.5H14.6667C15.125 15.5 15.5 15.125 15.5 14.6667V8C15.5 7.54167 15.125 7.16667 14.6667 7.16667H9.66667C9.20833 7.16667 8.83333 7.54167 8.83333 8V14.6667C8.83333 15.125 9.20833 15.5 9.66667 15.5ZM8.83333 1.33333V4.66667C8.83333 5.125 9.20833 5.5 9.66667 5.5H14.6667C15.125 5.5 15.5 5.125 15.5 4.66667V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H9.66667C9.20833 0.5 8.83333 0.875 8.83333 1.33333Z" fill="black" fillOpacity="0.54"/>
      </svg>
    )
}

export default DashboardIcon;