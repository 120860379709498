import React from 'react'
import { PropTypes } from 'prop-types'

import { axiosClient } from '../../core'
import { AddButton } from './styled'

const AddDocument = ({ children, height, onSuccess }) => {
  return <AddButton height={height}>
            <input type="file" hidden multiple onChange={(e) => {
              const formData = new FormData()
              for (let i = 0; i < e.target.files.length; i++) {
                formData.append("files", e.target.files[i])
              }
              axiosClient.post('/attachments/', formData, {
                headers: {
                  "Content-Type":'multipart/form-data'
                }
              })
              .then(res => onSuccess(res))
              .catch(err => console.log(err))
            }} />
            {children}
          </AddButton>
}

AddDocument.propTypes = {
  children: PropTypes.node,
  onSuccess: PropTypes.func,
  height: PropTypes.string
}
export default AddDocument;