import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MapDrawerCloseIcon, MapDrawerOpenIcon } from '../../components'
import { setDrawner } from '../../store/slices/fleet-map/slice'
import { MapDrawerIconWrapper } from './styled'

const DrawnerSwitcher = () => {
  const dispatch = useDispatch();
  const { drawnerOpen } = useSelector(state => state.fleetMap)

  return <MapDrawerIconWrapper onClick={() => dispatch(setDrawner(!drawnerOpen))}>{drawnerOpen  ? <MapDrawerCloseIcon /> : <MapDrawerOpenIcon /> }</MapDrawerIconWrapper>
}

export default DrawnerSwitcher