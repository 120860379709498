import React from 'react'

const DropdownIcon = () => {
    return (
			<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.239665 0.200408C0.543198 -0.081444 1.01775 -0.063868 1.2996 0.239665L4 3.14779L6.70041 0.239666C6.98226 -0.0638667 7.45681 -0.0814425 7.76034 0.20041C8.06388 0.482262 8.08145 0.95681 7.7996 1.26034L4.5496 4.76034C4.40769 4.91317 4.20855 5 4 5C3.79145 5 3.59232 4.91317 3.45041 4.76034L0.200408 1.26034C-0.081444 0.956808 -0.063868 0.48226 0.239665 0.200408Z" fill="#4A545E"/>
      </svg>


    )
}

export default DropdownIcon;