import React from 'react'

function MapPlusIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8332 6.83317H6.83317V11.8332H5.1665V6.83317H0.166504V5.1665H5.1665V0.166504H6.83317V5.1665H11.8332V6.83317Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MapPlusIcon
