import React from 'react'

const RightArrowIcon = () => {
    return (
			<svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.700408 0.239665C0.98226 -0.0638681 1.45681 -0.081444 1.76034 0.200408L5.26034 3.45041C5.41317 3.59232 5.5 3.79145 5.5 4C5.5 4.20856 5.41317 4.40769 5.26034 4.5496L1.76034 7.7996C1.45681 8.08145 0.98226 8.06388 0.700408 7.76034C0.418556 7.45681 0.436132 6.98226 0.739665 6.70041L3.64779 4L0.739665 1.2996C0.436132 1.01775 0.418556 0.543198 0.700408 0.239665Z" fill="#4A545E"/>
      </svg>
    )
}

export default RightArrowIcon