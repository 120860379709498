import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchUsers = createAsyncThunk(
  'fetchUsers',
  async () => {
    const requests = await axiosClient.get('/users/')
    
    return requests.data.results
  }
)

const fetchUser = createAsyncThunk(
  'fetchUser',
  async ({ id }) => {
    const requests = await axiosClient.get(`/users/${id}`)
    
    return requests.data.results
  }
)

const fetchMe = createAsyncThunk(
  'fetchMe',
  async () => {
    const request = await axiosClient.get('/users/me/')

    return request.data
  }
)

const initialState = {
  users: [],
  user: null,
  currentUser: null
}

export const slice = createSlice({
  name: 'requests',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.users = payload;
    })

    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    })

    builder.addCase(fetchMe.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
    })
  }
})

export {
  fetchUsers,
  fetchUser,
  fetchMe
}

export default slice.reducer;
