
import { 
  format,
  startOfYear,
  endOfYear
 } from 'date-fns'

export const prepPastMetricsDates = ({ date_from, date_to, isMounth, isYear }) => {
  if((!date_from) || (!date_to)){
    return { 
      date_from: null,
      date_to: null
    } 
  }
  const dateFrom = new Date(date_from)
  const dateTo = new Date(date_to)
  if(dateFrom.getTime() === dateTo.getTime()){
    return { 
      date_from: format(new Date().setDate(dateFrom.getDate() - 1), 'yyyy-MM-dd'),
      date_to: format(new Date().setDate(dateTo.getDate() - 1), 'yyyy-MM-dd')
    } 
  }

  if(isMounth) {
    return {
      date_from: format(new Date(date_from).setDate(0), 'yyyy-MM-01'),
      date_to: format(new Date(date_from).setDate(0), 'yyyy-MM-dd'),
    }
  }

  if(isYear) {
    return {
      date_from: format(startOfYear(new Date(date_from).setFullYear(new Date(date_from).getFullYear() - 1)), 'yyyy-MM-dd'),
      date_to: format(endOfYear(new Date(date_from).setFullYear(new Date(date_from).getFullYear() - 1)), 'yyyy-MM-dd'),
    }
  }

  return { 
    date_from: format(new Date(dateFrom).setDate(dateFrom.getDate() - ((dateTo - dateFrom) / 1000 / 60 / 60 / 24 + 1)), 'yyyy-MM-dd'),
    date_to: format(new Date(dateFrom).setDate(dateFrom.getDate() - 1), 'yyyy-MM-dd')
  } 
}