import { styled } from '@mui/material/styles'

export const DatePopupWrapper = styled('div')`
  position: absolute;
  top: 10px;
  left: 10px;
`
export const OdTTF = styled('div')`
  font-weight: 450;
  display: inline-block;
  font-size: 10px;
  line-height: 130%;
  text-transform: uppercase;
  color: #C53434;
  padding: 1px 4px;
  background: #FFEBEB;
  border-radius: 4px;
`

export const Header = styled('header')`
  padding: 12px 24px;
  border-bottom: 1px solid #E9ECEF;
`
export const TabsBody = styled('div')`
  background: #F0F3F5;
  width: 100%;
  padding: 0 24px;
`
export const TableContainer = styled('div')`
  flex-grow: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`

export const HistoryTableContainer = styled('div')`
  flex-grow: 1;
  max-height: 100%;
  display: flex;
  width: 60%;
  flex-wrap: wrap;  
  min-width: 500px;
`
export const MapCloseButton = styled('div')`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
`
export const MapWrapper = styled('div')`
  position: relative;
  border: 1px solid #CFD6DD;
  border-radius: 6px;
  height: 100%;
`
export const ExpandButtonWrapper = styled('div')`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  pointer-events: ${props => props.disabled ? 'none' : 'initial'};

  & div {
    opacity: ${props => props.disabled ? '0.5' : '1'};
  }

  & svg {
    opacity: ${props => props.disabled ? '0.5' : '1'};
  }

  &::before {
    content: "";
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: ${props => props.disabled ? 'block' : 'none'};
    background: rgba(0,0,0, 0.07);
  }
`
export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const Devider = styled('div')`
  width: 100%;
  height: 1px;
  background: #E9ECEF;
  margin: 32px 0;
`
export const TabButton = styled('div')`
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.isActive ? '#3062D4' : '#4A545E'};
  padding: 12px 8px;
  border-bottom: 2px solid ${props => props.isActive ? '#3062D4' : 'transparent'};

  &:hover {
    color: #3062D4;
    border-bottom: 2px solid #3062D4;
  }
`
export const ContentWrapper = styled('div')`
  padding: ${props => props.padding};
  flex: 1;
  max-height: calc(100% - 160px);
`
export const Preview = styled('div')`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  width: 140px;
  height: 140px;
  border: 1px solid #CFD6DD;
`