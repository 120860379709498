

import NumberFormat from 'react-number-format'
import {
  MetricArrowDownIcon,
  MetricArrowUpIcon
} from '../../components'

export const getMetricsDiff = ({ current, past, isReverse = false, measure }) => {
  const diff = (current / past - 1) * 100;
  if(diff === 0 || (current === 0 && past === 0)){
    return '0 %'
  } else if (past === 0) {
    return  <>
              <MetricArrowUpIcon style={{ marginRight: 5 }} color={isReverse ? '#C53434' : '#1D7C4D'} />
              <NumberFormat value={current} displayType={'text'} thousandSeparator=" " />&nbsp;{measure}
            </>
  } else if(diff > 0){
    return <>
             <MetricArrowUpIcon style={{ marginRight: 5 }} color={isReverse ? '#C53434' : '#1D7C4D'} />
             {`${diff.toFixed(1)} %`}
           </>
  } else if (diff < 0){ 
    return  <>
              <MetricArrowDownIcon style={{ marginRight: 5 }} color={isReverse ? '#1D7C4D' : '#C53434'} />
              {`${Math.abs(diff).toFixed(1)} %`}
            </>
  } 
}