import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  WarningIcon
} from '../../components'
import { 
  colorForFilters, 
  ChargeIconByCharge, 
  colorsForCharge,
  getChargeStatus
 } from '../../core'
import { 
  DrawnerItem,
  DrawnerTitle,
  DrawnerChargeWrapper,
  DrawnerStatusWrapper,
  DrawnerWarning,
  DrawnerStatusRow,
  DrawnerFixTime,
  DrawnerSubscription,
  DrawnerId,
  DrawnerModel,
 } from './styled'

const DrawnerUnit = ({
  uid,
  charge,
  status,
  subscriptionType,
  rtaPlateNumber,
  modelName,
  warning,
  isLast,
  flyToMarker,
  clickable = false
}) => {
  const { t } = useTranslation()
  const chargeStatus = getChargeStatus(charge);

  return (
    <DrawnerItem clickable={clickable} isLast={isLast} onClick={flyToMarker}>
      <div>
        <DrawnerTitle>
          {uid}
        </DrawnerTitle>
        <DrawnerChargeWrapper color={colorsForCharge[chargeStatus]}>
          {ChargeIconByCharge[chargeStatus]}
          {charge ? `${parseInt(charge)}%` : '—'}
        </DrawnerChargeWrapper>
      </div>
      <DrawnerStatusRow>
        <DrawnerStatusWrapper color={colorForFilters['status'][status]}>
          {t(`units.statuses.${status}`)}
        </DrawnerStatusWrapper>
        {warning && (
          <>
            <WarningIcon/>
            <DrawnerWarning>{warning}</DrawnerWarning>
          </>
        )}
        {status === "OUT_OF_ORDER" && <DrawnerFixTime>16 hrs left to fix</DrawnerFixTime>}
      </DrawnerStatusRow>
      <div>
        <DrawnerSubscription>{t(`units.subscriptions.${subscriptionType}`)}</DrawnerSubscription>
        <DrawnerId>{rtaPlateNumber}</DrawnerId>
      </div>
      <DrawnerModel>{modelName}</DrawnerModel>
    </DrawnerItem>
  )
}

DrawnerUnit.propTypes = {
  uid: PropTypes.string,
  charge: PropTypes.string,
  status: PropTypes.string,
  subscriptionType: PropTypes.string,
  rtaPlateNumber: PropTypes.string,
  modelName: PropTypes.string,
  warning: PropTypes.string,
  isLast: PropTypes.bool,
  clickable: PropTypes.bool,
  flyToMarker: PropTypes.func
}

export default DrawnerUnit;