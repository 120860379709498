import React from 'react'

const PrevPageIcon = () => {
    return (
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.19344 0.953126C6.4437 1.2153 6.43404 1.6307 6.17187 1.88095L1.85668 5.99999L6.17187 10.119C6.43404 10.3693 6.4437 10.7847 6.19344 11.0469C5.94319 11.309 5.52779 11.3187 5.26562 11.0684L0.453125 6.47469C0.323397 6.35086 0.25 6.17934 0.25 5.99999C0.25 5.82065 0.323397 5.64912 0.453125 5.52529L5.26562 0.931549C5.52779 0.681295 5.94319 0.690956 6.19344 0.953126Z" fill="#4A545E"/>
      </svg>



    )
}

export default PrevPageIcon 