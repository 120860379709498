import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core'
import { requestStatuses } from '../../../core/constants'

const fetchRequestsCount = createAsyncThunk(
  'fetchRequestsCount',
  async () => {
    const requests = await axiosClient.get('/service-requests/count/status/')
    return { ...requests.data, ALL: Object.values(requests.data).reduce((acc, item) => acc+=item, 0)}
  }
)

const fetchRequests = createAsyncThunk(
  'fetchRequests',
  async ({ filters }) => {
    const requests = await axiosClient.get('/service-requests/', {
      params: {
        ...filters,
      }
    })
    
    return requests.data
  }
)

const fetchRequest = createAsyncThunk(
  'fetchRequest',
  async ({ id }) => {
    const request = await axiosClient.get(`/service-requests/${id}`)

    return request.data
  }
)

const fetchRequestHistory = createAsyncThunk(
  'fetchRequestHistory',
  async ({ id }) => {
    const request = await axiosClient.get(`/service-requests/${id}/history/`)

    return request.data.results
  }
)

const initialState = {
  filter: requestStatuses.ALL,
  requests: {
    items: [],
    page: 1,
    page_size: 10,
    count: 0,
    order: '',
    direction: ''
  },
  request: null,
  requestsCount: null,
  requestHistory: null
}

export const slice = createSlice({
  name: 'requests',

  initialState,

  reducers: {
    setRequestsFilter(state, { payload }) {
      state.filter = payload
    },
    setRequests(state, { payload }) {
      state.requests = { ...state.requests, ...payload }
    },
    setRequest(state, { payload }) {
      state.request = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRequests.fulfilled, (state, { payload: { count, results } }) => {
      state.requests = {
        ...state.requests,
        count,
        items: results
      }
    })

    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      state.request = payload;
    })

    builder.addCase(fetchRequestHistory.fulfilled, (state, { payload }) => {
      state.requestHistory = payload;
    })

    builder.addCase(fetchRequestsCount.fulfilled, (state, { payload }) => {
      state.requestsCount = payload;
    })
  }
})

const {
  setRequestsFilter,
  setRequest,
  setRequests
} = slice.actions

export {
  setRequestsFilter,
  fetchRequests,
  fetchRequestHistory,
  setRequest,
  setRequests,
  fetchRequestsCount,
  fetchRequest
}

export default slice.reducer;
