import React, { useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import {
  Grid,
  MenuItem,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { fetchRequestsCount } from '../../store/slices/requests/slice'
import { axiosClient, problemTypes, useYupValidationResolver } from '../../core'
import { 
  Select,
  Text,
  PlusIcon,
  Button,
  TextInput,
  AddDocument
} from '../../components'
import { fetchBikes } from '../../store/slices/bikes/slice'
import { StyledForm } from './styled'

const CreateRequestForm = ({ bikeId, onClose, onSuccess }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const schema = yup.object().shape({
    unit_id: yup.mixed().required(t('errorMessages.required')),
    issue: yup.string().required(t('errorMessages.required')),
    description: yup.string().required(t('errorMessages.required')),
  })
  const resolver = useYupValidationResolver(schema)
  const { bikes } = useSelector(state => state.bikes)
  const { 
    control,
    handleSubmit,
     getValues,
     setValue,
     watch,
     formState: { errors }
     } = useForm({
    resolver,
    defaultValues: {
      unit_id: bikeId || null,
      issue: '',
      status: "CREATED",
      is_possible_to_use: true,
      attachment_ids: [],
      description: ''
    }
  })
  const onSubmit = (data) => {
    axiosClient.post('/service-requests/', data).then(() => {
      onSuccess()
      dispatch(fetchRequestsCount())
      onClose()
    })
  }
  const bikeIdOptions = useMemo(() => {
    if(!bikes?.length){
      return []
    }
    return bikes.map(({ uid, id }) => ({
      label: uid,
      value: id,
    }))
  }, [bikes])
  useEffect(() => {
    dispatch(fetchBikes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if(!bikeIdOptions.length){
    return null
  }
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('createRequestForm.labels.bikeId')}</Text>
          <Controller
            name="unit_id"
            control={control}
            render={({ field }) => 
            <FormControl fullWidth>
              <Select
                displayEmpty={true}
                renderValue={(selected) => {
                  if(!selected){
                    return t('createRequestForm.placeholders.bikeId')
                  }
                  return bikeIdOptions.find(({ value }) => value === selected)?.label
                }}
                disabled={Boolean(bikeId)}
                options={bikeIdOptions}
                renderOptions={({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )}
                {...field}
                ref={null}
            />
            </FormControl>
            }
          />
          <Text margin="5px 0 0 0" display="block" color="red">{errors?.bikeId?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('createRequestForm.labels.isMovingPossible')}</Text>
          <Text margin="8px 0 0 0">
            <Controller
              control={control}
              name="is_possible_to_use"
              render={({ field }) => (
                <RadioGroup
                  row={true}
                  defaultValue={true}
                  {...field}
                  ref={null}
                >
                  <FormControlLabel
                    value={true}
                    disableTypography
                    control={<Radio ref={null} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    disableTypography
                    control={<Radio ref={null} />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('createRequestForm.labels.issue')}</Text>
          <Controller
            name="issue"
            control={control}
            render={({ field }) => 
              <FormControl fullWidth>
                <Select
                  displayEmpty={true}
                  renderValue={(selected) => {
                    if(!selected){
                      return t('createRequestForm.placeholders.issue')
                    }
                    return t(`problemTypes.${selected}`)
                  }}
                  options={Object.values(problemTypes)}
                  renderOptions={(value) => (
                    <MenuItem key={value} value={value}>
                      {t(`problemTypes.${value}`)}
                    </MenuItem>
                  )}
                  {...field}
                  ref={null}
              />
              </FormControl>
            }
          />
          <Text margin="5px 0 0 0" display="block" color="red">{errors?.issue?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('createRequestForm.labels.media')}</Text>
          <AddDocument onSuccess={(res) => setValue('attachment_ids', [...getValues().attachment_ids, ...res.data.results.map(({ id }) => id)])}>
            <PlusIcon />
            <Text margin="0 5px 0 5px" fontWeight="500" fontSize="12px" color="#7E8B99">
              {watch('attachment_ids').length ? ` ${watch('attachment_ids').length} ${watch('attachment_ids').length > 1 ? "files" : "file"} uploaded` : t('buttons.addMedia')}
            </Text>
          </AddDocument>
        </Grid>
        <Grid item xs={12}>
          <Text margin="0 0 5px 0" display="block">{t('createRequestForm.labels.description')}</Text>
          <Controller
            name="description"
            control={control}
            render={({ field }) => 
              <FormControl fullWidth>
                <TextInput placeholder={t('createRequestForm.placeholders.description')} textarea width="100%" rows="5" {...field} ref={null}/>
              </FormControl>
            }
          />
          <Text margin="5px 0 0 0" display="block" color="red">{errors?.description?.message}</Text>
        </Grid>
        <Grid item marginLeft="auto" xs="auto">
          <Grid container spacing={1.5}>
            <Grid item xs="auto">
              <Button onClick={onClose} variant="outlined">{t('buttons.cancel')}</Button>
            </Grid>
            <Grid item xs="auto">
              <Button type="submit" variant='contained'>{t('buttons.create')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  )
}

CreateRequestForm.propTypes = {
  bikeId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}

CreateRequestForm.defaultProps = {
  bikeId: null,
}

export default CreateRequestForm