import React from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { MapPlusIcon, MapMinusIcon } from '../../components'

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  zIndex: 999,
  height: '50%'
}))

const Btn = styled(Button)(() => ({
  width: 30,
  height: 30,
  minWidth: 30,
  marginBottom: 7,
  backgroundColor: '#ffffff',
  padding: 0,
  border: '1px solid #CFD6DD',

  '&:hover': {
    backgroundColor: '#ffffff',
  },

  '&> svg': {
    verticalAlign: 'middle',
    color: '#000000',
  },
}))

const MapZoom = ({ zoomIn, zoomOut }) => {

  return (
    <Root>
      <Btn variant="outlined" onClick={zoomIn}>
        <MapPlusIcon />
      </Btn>
      <Btn variant="outlined" onClick={zoomOut}>
        <MapMinusIcon />
      </Btn>
    </Root>
  )
}

export default MapZoom
