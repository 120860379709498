import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`
export const LoaderWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
`
export const ChipItem = styled('div')`
  border: 1px solid ${props => props.borderColor || '#E9ECEF'};
  background: ${props => props.background || 'white'};
  border-radius: 5px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
`
export const Header = styled('header')`
  padding: 12px 16px;
  border-bottom: 1px solid #E9ECEF;
`
export const DropdownIconWrapper = styled('div')`
  transform: rotate(${props => props.isOpen ? '180deg' : 0});
  display: flex;
  margin-left: 10px;
`
export const CustomRangeWrapper = styled('div')`
  padding: 16px;
  border-left: 1px solid #E9ECEF;
`

export const AutocompleteIcon = styled('div')`
  position: absolute;
  bottom: ${props => props.bottom || '8px'};
  left: 10px;
`

export const SearchWithIcon = styled(TextField)`
  & input {
    margin-left: ${props => props.mL};
  }
`

export const BikeModal = styled('div')`
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  left: 20px;
  width: 250px;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px;
  background: white;
`

export const BikeModalHeader = styled('div')`
  background:  #F0F3F5;
  padding: 10px 16px;
  margin: -16px -16px 16px;
`