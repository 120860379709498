import React from 'react'
import { PropTypes } from 'prop-types';

import { StyledBadge } from './styled'

const Badge = ({ children, ...props}) => {
  return <StyledBadge {...props} >{children}</StyledBadge>
}

Badge.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  borderColor: PropTypes.string
}

export default Badge;