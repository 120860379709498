import React from 'react'
import MuiSelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles'

const StyledSelect = styled(MuiSelect)`
  & .MuiSelect-select {
    padding-top: ${props => props.outlined ? 0 : props.paddingTop || '16.5px'};
    padding-bottom: ${props => props.outlined ? 0 : props.paddingBottom || '16.5px'};
    box-sizing: border-box;
    height: ${props => props.outlined ? 'auto' : props.height || '56px'};
  }
`

const StyledInput = styled(InputBase)`
  & .MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
  }
`


const Select = ({ renderOptions, outlined = false, options = [], ...props }) => {

  return <StyledSelect input={outlined ? <StyledInput /> : null} outlined={outlined} {...props} >
    {options.map(renderOptions)}
  </StyledSelect>
}

export default Select;