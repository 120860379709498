import { styled } from '@mui/material/styles'

export const StyledInput = styled('input')`
  padding: 8px 16px;
  height: 40px;
  pointer-events: ${props => props.disabled ? 'none' : 'initial'}
  font-family: 'Inter';
  width: ${props => props.width || 'auto'};
  border: 1px solid #CFD6DD;
  border-radius: 6px;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  margin: ${props => props.margin};

  &::placeholder {
    color: #7E8B99;
  }
`

export const StyledTextArea = styled('textarea')`
  padding: 8px 16px;
  height: auto;
  font-family: 'Inter';
  pointer-events: ${props => props.disabled ? 'none' : 'initial'}
  resize: none;
  width: ${props => props.width || 'auto'};
  border: 1px solid #CFD6DD;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: ${props => props.margin};

  &::placeholder {
    color: #7E8B99;
  }
`