import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchCountries = createAsyncThunk(
  'fetchCountries',
  async () => {
    const response = await axiosClient.get('/countries/');
    return response.data.results;
  }
)

const initialState = {
  countries: [],
}

export const slice = createSlice({
  name: 'countries',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.countries = payload
    })
  }
})

export {
  fetchCountries,
}

export default slice.reducer;
