import React from 'react'

const AutocompletePlansIcon = () => {
    return (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 9.41421 4.16421 9.75 3.75 9.75C3.33579 9.75 3 9.41421 3 9C3 5.68629 5.68629 3 9 3C9.41421 3 9.75 3.33579 9.75 3.75C9.75 4.16421 9.41421 4.5 9 4.5ZM13.7803 4.21967C14.0732 4.51256 14.0732 4.98744 13.7803 5.28033L10.8417 8.21893C10.9436 8.45889 11 8.72286 11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C9.27714 7 9.54111 7.05637 9.78107 7.15827L12.7197 4.21967C13.0126 3.92678 13.4874 3.92678 13.7803 4.21967ZM9 8.5C8.72386 8.5 8.5 8.72386 8.5 9C8.5 9.27614 8.72386 9.5 9 9.5C9.27614 9.5 9.5 9.27614 9.5 9C9.5 8.72386 9.27614 8.5 9 8.5Z" fill="#555F6D"/>
      </svg>



    )
}

export default AutocompletePlansIcon