import React from 'react'

const MapDrawerCloseIcon = () => {
    return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 11.75C7 10.2312 8.23122 9 9.75 9H22.25C23.7688 9 25 10.2312 25 11.75V20.25C25 21.7688 23.7688 23 22.25 23H9.75C8.23122 23 7 21.7688 7 20.25V11.75ZM12.5 21.5H22.25C22.9404 21.5 23.5 20.9404 23.5 20.25V11.75C23.5 11.0596 22.9404 10.5 22.25 10.5H12.5V21.5ZM11 10.5V21.5H9.75C9.05964 21.5 8.5 20.9404 8.5 20.25V11.75C8.5 11.0596 9.05964 10.5 9.75 10.5H11ZM17.7483 13.1894C18.0579 13.4646 18.0857 13.9387 17.8106 14.2483L16.9201 15.25L20.75 15.25C21.1642 15.25 21.5 15.5858 21.5 16C21.5 16.4142 21.1642 16.75 20.75 16.75L16.9201 16.75L17.8106 17.7517C18.0857 18.0613 18.0579 18.5354 17.7483 18.8106C17.4387 19.0857 16.9646 19.0579 16.6894 18.7483L14.6894 16.4983C14.4369 16.2141 14.4369 15.7859 14.6894 15.5017L16.6894 13.2517C16.9646 12.9421 17.4387 12.9143 17.7483 13.1894Z" fill="#7E8B99"/>
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#CFD6DD"/>
      </svg>


    )
}

export default MapDrawerCloseIcon;