import React, { useRef, useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';

import { PreviewImage } from './styled'


export const Gallery = (props) => {
  return <ImageGallery {...props} />
}

export const Video = ({ videoUrl, poster }) => {
  const videoRef = useRef(null)
  const [isPreview, setIsPreview] = useState(true)
  useEffect(() => () => videoRef.current?.pause())
  return (
    <div style={{ position: 'relative' }}>
      <PreviewImage hidden={!isPreview} image={poster}>
        <div onClick={() => {
          setTimeout(() => videoRef.current.play(), 100)
          setIsPreview(false)
        }}>
          <div style={{ cursor: "pointer" }}>
            <svg width={35} height={35} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PlayCircleOutlineIcon" title="PlayCircleOutline"><path d="m10 16.5 6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>
          </div>
        </div>
      </PreviewImage>
      <video style={{ opacity: isPreview ? '0': '1', pointerEvents: isPreview ? 'none': 'initial'}} onLoadedData={() => videoRef.current.play()} ref={videoRef} width="100%" height="100%" controls="controls">
        <source src={videoUrl}/>
      </video>
    </div>
  );
}