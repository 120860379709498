import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchBike = createAsyncThunk(
  'fetchBike',
  async ({ id }) => {
    const response = await axiosClient.get(`/bikes/${id}`)
    return response.data;
  }
)

const fetchBikes = createAsyncThunk(
  'fetchBikes',
  async () => {
    const response = await axiosClient.get(`/bikes/`)
    return response?.data?.results || []
  }
)

const fetchBikeRequests = createAsyncThunk(
  'fetchBikeRequests',
  async ({ id, ...rest }) => {
    const response = await axiosClient.get('/service-requests/', {
      params: {
        unit_id: id,
        ...rest
      }
    })
    return response.data;
  }
)

const fetchBikeModels = createAsyncThunk(
  'fetchBikeModels',
  async () => {
    const response = await axiosClient.get('/bike-models/')
    return response.data.results;
  }
)

const fetchBikeClientHistory = createAsyncThunk(
  'fetchBikeClientHistory',
  async ({ id, ...rest }) => {
    const response = await axiosClient.get(`/bikes/${id}/client-history/`, {
      params: {
        ...rest
      }
    })
    return response.data;
  }
)

const initialState = {
  bike: null,
  bikes: [],
  bikeClientHistory: {
    items: [],
    page: 1,
    page_size: 10,
    count: 0
  },
  bikeRequests: {
    items: [],
    page: 1,
    page_size: 10,
    count: 0
  },
  bikesModels: []
}

export const slice = createSlice({
  name: 'bike',
  initialState,
  reducers: {
    resetBike(state) {
      state.bike = null
      state.bikeClientHistory = {
        items: [],
        page: 1,
        page_size: 10,
        count: 0
      }
      state.bikeRequests = {
        items: [],
        page: 1,
        page_size: 10,
        count: 0
      }
    },
    setBikeRequests(state, { payload }) {
      state.bikeRequests = { ...state.bikeRequests, ...payload }
    },
    setBikeClientHistory(state, { payload }) {
      state.bikeClientHistory = { ...state.bikeClientHistory, ...payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBike.fulfilled, (state, { payload }) => {
      state.bike = payload
    })

    builder.addCase(fetchBikes.fulfilled, (state, { payload }) => {
      state.bikes = payload
    })

    builder.addCase(fetchBikeRequests.fulfilled, (state, { payload: { results, count }}) => {
      state.bikeRequests = {
        ...state.bikeRequests,
        items: results,
        count
      }
    })

    builder.addCase(fetchBikeClientHistory.fulfilled, (state, { payload: { results, count }}) => {
      state.bikeClientHistory = {
        ...state.bikeClientHistory,
        items: results,
        count
      }
    })

    builder.addCase(fetchBikeModels.fulfilled, (state, { payload }) => {
      state.bikesModels = payload
    })
  }
})

const {
  resetBike,
  setBikeClientHistory,
  setBikeRequests
} = slice.actions

export {
  fetchBike,
  fetchBikes,
  fetchBikeRequests,
  resetBike,
  fetchBikeModels,
  setBikeClientHistory,
  setBikeRequests,
  fetchBikeClientHistory,
}

export default slice.reducer;
