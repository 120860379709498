import { styled } from '@mui/material/styles'

export const Header = styled('header')`
  padding: 12px 24px;
  border-bottom: 1px solid #E9ECEF;
`


export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;s
`

export const ContentWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
`

export const Preview = styled('div')`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px solid #CFD6DD;
`