import { styled } from '@mui/material/styles'

export const StyledBadge = styled('div')`
  border-radius: 16px;
  padding: 4px 6px;
  min-width: 24px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: ${props => props.color || '#05205E'};
  font-weight: 400;
  font-size: 12px;
  background: ${props => props.background || '#E9ECEF'};
  border: 2px solid ${props => props.borderColor || '#E9ECEF'};
`