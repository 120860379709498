import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchOwnerships = createAsyncThunk(
  'fetchOwnerships',
  async () => {
    const response = await axiosClient.get('/asset-ownerships/');
    return response.data.results;
  }
)

const initialState = {
  ownerships: [],
}

export const slice = createSlice({
  name: 'ownerships',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchOwnerships.fulfilled, (state, { payload }) => {
      state.ownerships = payload
    })
  }
})

export {
  fetchOwnerships,
}

export default slice.reducer;
