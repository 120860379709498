import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';


export const StyledTableHead = styled(TableHead)`
  & th {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #202532;
    background: #F5F7F9;
  }
`

export const NextButton = styled('div')`
  border: 1px solid #CFD6DD;
  border-radius: 0px 5.25px 5.25px 0px;
  padding: 8px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'none': 'pointer'};
  height: 35px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none': 'initial'};
`

export const PrevButton = styled(NextButton)`
  border-radius: 5.25px 0px 0px 5.25px;
  margin-right: -1px;
`

export const CellInner = styled('div')`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const TableScrollWrapper = styled('div')`
  max-height: 100%;
  overflow: auto;
  width: 100%;
  border: 1px solid #BEC0C5;
  border-radius: 6px;
  display: flex;
`

export const StyledTableCell = styled(TableCell)`
  font-weight: 400;
  font-size: 14px;
  padding: 8px 16px;
  height: 55px;
  line-height: 24px;
  color: #272E35;
`