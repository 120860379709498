import React from 'react'

function MetricArrowDownIcon({ color, ...rest }) {
  return (
    <svg {...rest} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.51402 9.79615C4.22524 10.068 3.77477 10.068 3.48598 9.79615L0.235978 6.79615C-0.0656517 6.51226 -0.080035 6.03761 0.203852 5.73598C0.48774 5.43435 0.962394 5.41996 1.26402 5.70385L3.25 7.51418L3.25 0.75C3.25 0.335787 3.58579 0 4 0C4.41421 0 4.75 0.335787 4.75 0.75L4.75 7.51418L6.73598 5.70385C7.03761 5.41996 7.51226 5.43435 7.79615 5.73598C8.08004 6.03761 8.06565 6.51226 7.76402 6.79615L4.51402 9.79615Z" fill={color}/>
    </svg>

  )
}

export default MetricArrowDownIcon
