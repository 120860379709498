import { styled } from '@mui/material/styles'

export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
`

export const TableContainer = styled('div')`
  padding: 16px 24px;
  flex-grow: 1;
  max-height: calc(100% - 60px);
`

export const NoData = styled('div')`
  margin-top: 20px;
  text-align: center;
`

export const Header = styled('header')`
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid #E9ECEF;
`

export const OdTTF = styled('div')`
  font-weight: 450;
  display: inline-block;
  font-size: 10px;
  line-height: 130%;
  text-transform: uppercase;
  color: #C53434;
  padding: 1px 4px;
  background: #FFEBEB;
  border-radius: 4px;
`