import { getMediaUrl } from ".."
import { fileTypes } from ".."
 
 
 export const normalizeDocuments = (attachments) => {
  const documents = attachments?.filter(({ type }) => fileTypes.DOCUMENT === type)
  if(!documents?.length){
    return null
  }
  return documents.map(item => ({
    ...item,
    url: `${getMediaUrl()}${item.url}`
  }))
}