import { styled } from '@mui/material/styles'

export const MapTools = styled('div')(() => ({
  position: 'absolute',
  display: 'flex',
  flexWrap: 'wrap',
  zIndex: 999,
  top: 10,
  right: 10,
  bottom: 10,
  maxHeight: '95%',
  width: 30,
}))

export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
export const MapWrapper = styled('div')`
  position: relative;
  flex-grow: 1;
`
export const DrawnerBody = styled('div')`
  padding: 8px;
  display: flex;
  max-height: 100%;
  overflow: auto;
  z-index: 3;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
`

export const DrawnerItem = styled('div')`
  margin-bottom: ${props => props.isLast ? '0' : '10px'};
  padding: 8px 12px;
  cursor: ${props => props.clickable ? 'pointer' : 'initial' };
  border: 1px solid #CFD6DD;
  border-radius: 4px;
  width: 230px;
`

export const DrawnerTitle = styled('div')`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #272E35;
  margin-right: 8px;
  display: inline-block;
`

export const DrawnerChargeWrapper = styled('span')`
  color: ${props => props.color};
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;

  & svg {
    margin-right: 2px;
    margin-bottom: 1px;
    display: inline-block;
  }
`

export const DrawnerStatusWrapper = styled('div')`
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #202532;
  margin-right: 4px;
  display: inline-block;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props => props.color}
  }
`

export const DrawnerSubscription = styled('div')`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #272E35;
  margin-right: 8px;
  display: inline-block;
`

export const DrawnerId = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #555F6D;
  display: inline-block;
`

export const DrawnerModel = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #272E35;
`

export const DrawnerWarning = styled('div')`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #272E35;
  margin-left: 4px;
  display: inline-block;
`
export const DrawnerStatusRow = styled('div')`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`
export const DrawnerFixTime = styled('div')`
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #555F6D;
  margin-left: 4px;
  display: inline-block;
`