import { styled } from '@mui/material/styles'

export const HeaderWrapper = styled('header')`
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid #E9ECEF;
`

export const HeaderTopRow = styled('div')`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const HeaderCation = styled('div')`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const ViewSwitcherButton = styled('div')`
  cursor: pointer;
  border-top-left-radius: ${props => props.isFirst ? '6px' : "0"};
  border-bottom-left-radius: ${props => props.isFirst ? '6px' : "0"};
  border-top-right-radius: ${props => props.isLast ? '6px' : "0"};
  border-bottom-right-radius: ${props => props.isLast ? '6px' : "0"};
  color: #3A424A;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 12px;
  background : ${props => props.isActive ? "#CFD6DD" : "#fff"};
  border: 1px solid #CFD6DD;

  &:hover {
    background : #CFD6DD;
  }
`
export const ViewSwitcherLabel = styled('div')`
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #272E35;
  line-height: 24px;
`

export const ViewFilterItemWrapper = styled('div')`
  padding-left: 12px;
  display: flex;
  align-items: center;
`
export const ViewFilterItemLabel = styled('div')`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0 4px;
  display: flex;
  align-items: center;
`
export const ViewFilterWrapper = styled('div')`
  margin-left: -12px;
  display: flex;
  align-items: center;
`
export const ViewFilterItem = styled('div')`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
  background: ${props => props.selected ? 'rgba(16, 40, 72, 0.09)' : '#fff'};

  &:hover {
    background: rgba(2, 47, 82, 0.06);
    opacity: 1;

    & .badge {
      background: #fff;
    }
  }
`

export const MapDrawerIconWrapper = styled('span')`
  margin-right: 15px;
  cursor: pointer;
`