import React from 'react'

const MapDrawerOpenIcon = () => {
    return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 11.75C7 10.2312 8.23122 9 9.75 9H22.25C23.7688 9 25 10.2312 25 11.75V20.25C25 21.7688 23.7688 23 22.25 23H9.75C8.23122 23 7 21.7688 7 20.25V11.75ZM12.5 21.5H22.25C22.9404 21.5 23.5 20.9404 23.5 20.25V11.75C23.5 11.0596 22.9404 10.5 22.25 10.5H12.5V21.5ZM11 10.5V21.5H9.75C9.05964 21.5 8.5 20.9404 8.5 20.25V11.75C8.5 11.0596 9.05964 10.5 9.75 10.5H11ZM18.2517 13.1894C18.5613 12.9143 19.0354 12.9421 19.3106 13.2517L21.3106 15.5017C21.5631 15.7859 21.5631 16.2141 21.3106 16.4983L19.3106 18.7483C19.0354 19.0579 18.5613 19.0857 18.2517 18.8106C17.9421 18.5354 17.9143 18.0613 18.1894 17.7517L19.0799 16.75L15.25 16.75C14.8358 16.75 14.5 16.4142 14.5 16C14.5 15.5858 14.8358 15.25 15.25 15.25L19.0799 15.25L18.1894 14.2483C17.9143 13.9387 17.9421 13.4646 18.2517 13.1894Z" fill="#7E8B99"/>
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#CFD6DD"/>
      </svg>
    )
}

export default MapDrawerOpenIcon;