import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { 
  format,
  startOfWeek,
  endOfWeek,
  lastDayOfMonth,
  startOfYear,
  endOfYear
 } from 'date-fns'
 import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
 import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
 import { DatePicker } from '@mui/x-date-pickers/DatePicker'
 import {
   Menu,
   MenuItem,
   Grid,
 } from '@mui/material'

 
import { 
  Button, 
  Text,
  CheckIcon,
  AutocompleteCalendarIcon,
  DropdownIcon,
} from '../../components'

import {
  DropdownIconWrapper,
  CustomRangeWrapper
} from './styled'

const DatePopup = ({ initialDate, setDate, allTime = true }) => {
  const { t } = useTranslation()
  const dateDropdownItems = [
    {
      label: t("date.today"),
      value: {
        date_from: format(new Date(), 'yyyy-MM-dd'),
        date_to: format(new Date(), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.yesterday"),
      value: {
        date_from: format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
        date_to: format(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.thisWeek"),
      value: {
        date_from: format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        date_to: format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.lastWeek"),
      value: {
        date_from: format(startOfWeek(new Date().setDate(new Date().getDate() - 7), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        date_to: format(endOfWeek(new Date().setDate(new Date().getDate() - 7), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.thisMonth"),
      value: {
        date_from: format(new Date(), 'yyyy-MM-01'),
        date_to: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.lastMonth"),
      value: {
        date_from: format(new Date().setDate(0), 'yyyy-MM-01'),
        date_to: format(new Date().setDate(0), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.thisYear"),
      value: {
        date_from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
        date_to: format(endOfYear(new Date()), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.lastYear"),
      value: {
        date_from: format(startOfYear(new Date().setFullYear(new Date().getFullYear() - 1)), 'yyyy-MM-dd'),
        date_to: format(endOfYear(new Date().setFullYear(new Date().getFullYear() - 1)), 'yyyy-MM-dd'),
      }
    },
    {
      label: t("date.allTime"),
      value: {
        date_from: null,
        date_to: null,
      }
    },
  ]
  const [dateForFilter, setDateForFilter] = useState(initialDate || dateDropdownItems[0])
  const [filterDate, setFilterDate] = useState(0)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [customDateFrom, setCustomDateFrom] = useState(null)
  const [customDateTo, setCustomDateTo] = useState(null)
  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false)
  const handleClose = () => {
    setMenuAnchor(null)
    setIsCustomRangeOpen(false)
  }
  const handleMenuClick = (index) => {
    setFilterDate(index)
    setDateForFilter(dateDropdownItems[index])
    setIsCustomRangeOpen(false)
  }
  const customRangeCancel = () => {
    setIsCustomRangeOpen(false)
    setCustomDateFrom(null)
    setCustomDateTo(null)
  }
  const customRangeApply = () => {
    setIsCustomRangeOpen(false)
    setCustomDateFrom(null)
    setCustomDateTo(null)
    setFilterDate(9)
    setDateForFilter({
      label: `${format(new Date(customDateFrom.$d), 'dd MMM`yy')} - ${format(new Date(customDateTo.$d), 'dd MMM`yy')}`,
      value: {
        date_from: format(new Date(customDateFrom.$d), 'yyyy-MM-dd'),
        date_to: format(new Date(customDateTo.$d), 'yyyy-MM-dd'),
      }
    })
  }
  useEffect(() => {
    handleClose()
    setDate(dateForFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForFilter])
  return (
    <>
      <Button
        variant="outlined"
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        <Text margin="0 0 0 -4px" ><AutocompleteCalendarIcon /></Text>
        <Text fontSize="14px" fontWeight="600" margin="0 0 0 7px" >{dateForFilter.label}</Text>
        <DropdownIconWrapper isOpen={Boolean(menuAnchor)}>
          <DropdownIcon />
        </DropdownIconWrapper>
      </Button>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <MenuItem onClick={() => setIsCustomRangeOpen(true)} style={{ borderBottom: "1px solid #E9ECEF", paddingLeft: filterDate === 9 || isCustomRangeOpen ? 10 : 33 }}>
                {(filterDate === 9 || isCustomRangeOpen) && <><CheckIcon />&nbsp;&nbsp;</>}{t("date.customRange")}
              </MenuItem>
              {
                dateDropdownItems.filter(({ value: { date_from } }) => allTime || Boolean(date_from)).map(({ label }, index) => 
                  <MenuItem key={label} onClick={() => handleMenuClick(index)} style={{  paddingLeft: filterDate === index && !isCustomRangeOpen  ?  10 : 33 }}>
                    {filterDate === index && !isCustomRangeOpen  && <><CheckIcon />&nbsp;&nbsp;</> }{label}
                  </MenuItem>)
              }
            </div>
            {
              isCustomRangeOpen && (
                <CustomRangeWrapper>
                  <Text margin="0 0 5px 0" display="block">Date from</Text>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={customDateFrom}
                      onChange={e => setCustomDateFrom(e)}
                      className="smallDatePicker fullWidth"
                    />
                  </LocalizationProvider>
                  <Text margin="5px 0 5px 0" display="block">Date to</Text>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={!customDateFrom}
                      minDate={customDateFrom}
                      value={customDateTo}
                      onChange={e => setCustomDateTo(e)}
                      className="smallDatePicker fullWidth"
                    />
                  </LocalizationProvider>
                  <Grid item marginTop={2} xs="auto">
                    <Grid container spacing={1.5}>
                      <Grid item xs={6}>
                        <Button fullWidth variant="outlined" onClick={customRangeCancel}>{t('buttons.cancel')}</Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button disabled={!customDateFrom || !customDateTo} fullWidth onClick={customRangeApply} variant='contained'>{t('buttons.apply')}</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomRangeWrapper>
              )
            }
          </div>
        </Menu>
    </>
  )
}

DatePopup.propTypes = {
  initialDate: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: {
      date_from: PropTypes.string.isRequired,
      date_to: PropTypes.string.isRequired,
    }
  }),
  setDate: PropTypes.func.isRequired,
  allTime: PropTypes.bool,
}

export default DatePopup