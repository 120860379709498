import { styled } from '@mui/material/styles'


 export const TextComponent = styled('div')`
  white-space: ${props => props.nowrap || props.ellipsis ? 'nowrap' : 'initial'};
  display: ${props => props.display || 'inline-flex'};
  font-weight: ${props => props.fontWeight || 400};
  font-size: ${props => props.fontSize || '14px'};
  color: ${props => props.color || '#272E35'};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  line-height: ${props => props.lineHeight || '130%'};
  font-family: ${props => props.fontFamily || 'Inter'};
  text-overflow: ${props => props.ellipsis ? 'ellipsis' : 'initial'};
  overflow: ${props => props.ellipsis ? 'hidden' : 'initial'};
  text-transform: ${props => props.uppercase ? 'uppercase' : 'initial'};
`

