import React from 'react'

 const MapCloseIcon = () => {
    return (
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_640_36865)">
        <rect x="2" y="1" width="32" height="32" rx="6" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.2197 12.2197C13.5126 11.9268 13.9874 11.9268 14.2803 12.2197L18 15.9393L21.7197 12.2197C22.0126 11.9268 22.4874 11.9268 22.7803 12.2197C23.0732 12.5126 23.0732 12.9874 22.7803 13.2803L19.0607 17L22.7803 20.7197C23.0732 21.0126 23.0732 21.4874 22.7803 21.7803C22.4874 22.0732 22.0126 22.0732 21.7197 21.7803L18 18.0607L14.2803 21.7803C13.9874 22.0732 13.5126 22.0732 13.2197 21.7803C12.9268 21.4874 12.9268 21.0126 13.2197 20.7197L16.9393 17L13.2197 13.2803C12.9268 12.9874 12.9268 12.5126 13.2197 12.2197Z" fill="#4A545E"/>
        <rect x="2.5" y="1.5" width="31" height="31" rx="5.5" stroke="#CFD6DD"/>
        </g>
        <defs>
        <filter id="filter0_d_640_36865" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.12 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_640_36865"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_640_36865" result="shape"/>
        </filter>
        </defs>
      </svg>
    )
}

export default MapCloseIcon