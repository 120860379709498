import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { login, getToken } from '../../core'
import { TextInput, LogoIcon, Button, LoaderIcon } from '../../components'
import { PageWrapper, StyledForm } from './styled'

const Login = () => {

  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { control, handleSubmit } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    login(data).then(() => navigate('/fleet/map')).finally(() => setLoading(false))
  }

  if(getToken()){
    return <Navigate to="/fleet/map" replace />
  }
  
  return (
    <PageWrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)}> 
        <LogoIcon />
        <Controller
          defaultValue=""
          name="username"
          control={control}
          render={({ field }) => 
            <TextInput
              placeholder={t('loginForm.placeholders.login')}
              margin="16px 0 16px 0"
              {...field}
              ref={null}
            />
          }
        />
        
        <Controller
          defaultValue=""
          name="password"
          control={control}
          render={({ field }) => 
            <TextInput
              type="password"
              placeholder={t('loginForm.placeholders.password')}
              margin="0 0 16px 0"
              {...field}
              ref={null}
            />
          }
        />
        <Button variant='contained' type="submit">
          {loading ? <LoaderIcon /> : t('loginForm.buttons.login')}
        </Button>
      </StyledForm>
    </PageWrapper>
  )
}

export default Login