import { 
  CharginIcon,
  HighChargeIcon,
  LowChargeIcon,
  MediumChargeIcon,
  NoBatteryIcon
} from '../../components'

export const unitStatuses = ['READY', 'IN_MOTION', 'OUT_OF_ORDER','SERVICE'];
export const SubscriptionFilters = ['B', 'M', 'U'];
export const FleetFilters = {
  'subscription_type': SubscriptionFilters,
  'status': unitStatuses,
}

export const colorForFilters = {
  subscription_type: {
    B: '#79BCF6', 
    M: '#75CC9E', 
    U: '#F6A351', 
  },
  status: {
    READY: '#79BCF6', 
    IN_MOTION: '#75CC9E', 
    OUT_OF_ORDER: '#F49090', 
    SERVICE: '#ABA2FB', 
  },
}

export const fieldByFilterBy = {
  subscription_type: 'subscription_type',
  status: 'status',
}

export const colorsForCharge = {
  LOW: '#C53434',
  MEDIUM: '#F59638',
  HIGH: '#1D7C4D',
  CHARGING: '#3062D4',
  MISSING: 'grey'
}

export const chargeStatuses = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  CHARGING: 'CHARGING',
  MISSING: 'MISSING'
}

export const ChargeIconByCharge = {
  LOW: <LowChargeIcon/>,
  MEDIUM: <MediumChargeIcon/>,
  HIGH: <HighChargeIcon/>,
  CHARGING: <CharginIcon/>,
  MISSING: <NoBatteryIcon/>,
}

