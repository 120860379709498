import React from 'react'

const BellIcon = () => {
    return (
			<svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9998 16.3333C7.91646 16.3333 8.66646 15.5833 8.66646 14.6666H5.33313C5.33313 15.5833 6.0748 16.3333 6.9998 16.3333ZM11.9998 11.3333V7.16658C11.9998 4.60825 10.6331 2.46659 8.2498 1.89992V1.33325C8.2498 0.641585 7.69146 0.083252 6.9998 0.083252C6.30813 0.083252 5.7498 0.641585 5.7498 1.33325V1.89992C3.35813 2.46659 1.9998 4.59992 1.9998 7.16658V11.3333L0.924799 12.4083C0.399799 12.9333 0.766465 13.8333 1.50813 13.8333H12.4831C13.2248 13.8333 13.5998 12.9333 13.0748 12.4083L11.9998 11.3333Z" fill="black" fillOpacity="0.54"/>
      </svg>

    )
}

export default BellIcon;