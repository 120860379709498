import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

import { removeToken } from '../../core'
import { SettingsIcon, LogoutIcon } from '../icons';
import {
  UserAvatar,
  UserTitle,
  UserSubTitle, 
  UserBlockWrapper,
  UserMenuItem
 } from './styled'


const UserBlock = ({ currentUser }) => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    removeToken()
    navigate('/')
  }

  if(!currentUser){
    return null
  }

  return (
    <>
      <UserBlockWrapper onClick={handleClick}>
        <UserAvatar>{`${currentUser.first_name[0]}${currentUser.last_name[0]}`}</UserAvatar>
        <div>
          <UserTitle>
            {`${currentUser.first_name} ${currentUser.last_name}`}
          </UserTitle>
          <UserSubTitle>
            B2B Client
          </UserSubTitle>
        </div>
      </UserBlockWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ transform: 'translate(-40px, -50px)' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{ padding: 8, minWidth: 200 }} elevation={3} >
          <UserMenuItem> 
            <SettingsIcon />
            Settings
          </UserMenuItem>
          <UserMenuItem onClick={logout} color="red">
            <LogoutIcon />
            Log out
          </UserMenuItem>
        </Paper>
      </Popover>
    </>
  )
}

export default UserBlock