import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Dialog } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchRequests, setRequests } from '../../store/slices/requests/slice'
import {
  getDateString,
  getTTF,
  colorForFilters,
  normalizeAttachments,
  CircleColorsByStatus,
  fileTypes
} from '../../core'
import { 
  CommonTable,
  Text,
  StatusCircle,
  ImageIcon,
  Button,
  CreateRequestForm,
  VideoIcon
} from '../../components'
import {
  PageWrapper,
  TableContainer,
  Header,
  NoData,
  OdTTF
} from './styled'

const Requests = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const { requests: { items: requests, page_size, page, order, direction } } = useSelector(state => state.requests)

  const columns = [
    {
      headCell: t("requests.tableHeadCells.created"),
      field: 'uid',
      getValue: (row) => <>
        <Text nowrap margin="0 0 -5px 0" display="flex" fontWeight="600" >{getDateString(new Date(row.request.created))}</Text>
        <Text nowrap fontWeight="450" fontSize="10px ">{row.request.uid}</Text>
      </>
    },
    {
      headCell: t("requests.tableHeadCells.unitId"),
      field: 'unit_uid',
      getValue: (row) => <Text>{row.unit.id}</Text>
    },
    {
      headCell: t("requests.tableHeadCells.status"),
      field: 'status',
      getValue: (row) => 
        <Text>
          <StatusCircle {...CircleColorsByStatus[row.status]}>{t(`requests.statuses.${row.status}`)}</StatusCircle>
        </Text>
    },
    {
      headCell: t("requests.tableHeadCells.ttf"),
      field: 'time_to_fix',
      getValue: (row) => {
        const ttf = getTTF(row.ttf)
        if(ttf === 'OD'){
          return <OdTTF>{ttf}</OdTTF>
        }
        return ttf
      }
    },
    {
      headCell: t("requests.tableHeadCells.client"),
      field: 'client',
      getValue: (row) => row.client
    },
    {
      headCell: t("requests.tableHeadCells.unitStatus"),
      field: 'unit_status',
      getValue: (row) =>
        <Text fontWeight="500">
          <StatusCircle background={colorForFilters.status[row.unit.status]}>{t(`units.statuses.${row.unit.status}`)}</StatusCircle>
        </Text>
    },
    {
      headCell: t("requests.tableHeadCells.issue"),
      field: 'issue',
      getValue: (row) => {
        const attachments = normalizeAttachments(row.attachments)
        return <Grid container rowSpacing={0} columnSpacing={1} alignItems="center">
                <Grid xs="auto" item>{row.issue}</Grid>
                {attachments && attachments[fileTypes.IMAGE] && <Grid xs="auto" item display="flex" alignItems="center"><ImageIcon /></Grid>}
                {attachments && attachments[fileTypes.VIDEO] && <Grid xs="auto" item display="flex" alignItems="center"><VideoIcon /></Grid>}
              </Grid>
      }
    },
    {
      headCell: t("requests.tableHeadCells.assignee"),
      field: 'assignee_name',
      getValue: (row) => row.assignee
    },
  ]

  const sortHandler = (field) => {
    if(field === order && direction === 'desc'){
      dispatch(setRequests({
        order: '',
        direction: ''
      }))
    } else if(field === order && direction === 'asc'){
      dispatch(setRequests({
        direction: 'desc'
      }))
    } else {
      dispatch(setRequests({
        order: field,
        direction: 'asc'
      }))
    }
  }
  useEffect(() => {
    dispatch(fetchRequests({
      filters: { 
        status: searchParams.get('status'), 
        page, 
        page_size,
        order: order ? `${direction === 'desc' ? '-' : ''}${order}`: null }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('status'), page, page_size, order, direction])
  const tabelData = useMemo(() => requests.map(request => ({
    request: {
      created: request.created,
      id: request.id,
      uid: request.uid,
    },
    unit: {
      id: request.unit.uid,
      status: request.unit.status
    },
    status: request.status,
    ttf: request.time_to_fix,
    client: request.client.name,
    issue: t(`problemTypes.${request.issue}`),
    attachments: request.attachments,
    assignee: request.assignee_id ? `${request?.assignee?.first_name || ''} ${request?.assignee?.last_name || ''}` : t('other.unassigned'),
    rowBg: getTTF(request.time_to_fix) === 'OD' ? '#FFFAFA' : 'initial'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })) , [requests])

  return (
    <PageWrapper>
      <Header>
        <Grid container columnSpacing={2} rowSpacing={0} alignItems="center" justifyContent="space-between">
          <Grid item xs='auto'>
            <Text fontWeight="600" fontSize="18px">
              {t('requests.headerTitle')}
            </Text>
          </Grid>
          <Grid item xs='auto'>
            <Button onClick={() => setIsCreateRequestModalOpen(true)} variant="outlined" size="small">{t('buttons.createRequest')}</Button>
          </Grid>
        </Grid>
      </Header>
      {
        requests?.length
          ? (
            <TableContainer>
              <CommonTable
                pagination={{
                  paginationSelector: state => state.requests.requests,
                  pagintaionSetter: props => dispatch(setRequests(props))
                }}
                order={order}
                direction={direction}
                onHeadCellClick={sortHandler}
                columns={columns}
                data={tabelData}
                onRowClick={(row) => navigate(`/request/${row.request.id}`, { state: { backpath: `/requests?status=${searchParams.get('status')}`}})}
              />
            </TableContainer>
          )
          : <NoData>No data</NoData>
      }
      <Dialog open={isCreateRequestModalOpen} onClose={() => setIsCreateRequestModalOpen(false)} fullWidth maxWidth="md">
        <CreateRequestForm onSuccess={() => dispatch(fetchRequests({ filters: { status: searchParams.get('status')}}))} onClose={() => setIsCreateRequestModalOpen(false)} />
      </Dialog>
    </PageWrapper>
  )
}

export default Requests