import React from 'react'
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles'

const StyledButton = styled(MuiButton)({
  height: 40,
  textTransform: 'initial',

  '&.MuiButton-sizeSmall': {
    height: 32,
  },

  '&.MuiButton-outlined': {
    borderColor: '#CFD6DD',
    color: 'grey',
    background: '#fff'
  }
})


const Button = (props) => {

  return <StyledButton {...props} />
}

export default Button;