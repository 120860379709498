import React from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 999,
  justifyContent: 'flex-end',
  height: '50%'
}))

const Btn = styled(Button)(() => ({
  width: 30,
  height: 30,
  minWidth: 30,
  marginBottom: 7,
  backgroundColor: '#ffffff',
  padding: 0,
  border: '1px solid #CFD6DD',
  overflow: 'hidden',

  '&:hover': {
    backgroundColor: '#ffffff',
  },
}))

const MapStylesSwitcher = ({ setStyle }) => {

  const mapStyles = [
    {
      src: 'https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM',
      img: '/images/mono.png'
    },
    {
      src: 'https://api.maptiler.com/maps/streets-v2/style.json?key=SvQLtzWmUvzNtKqVUcYM',
      img: '/images/streets.png'
    },
    {
      src: 'https://api.maptiler.com/maps/hybrid/style.json?key=SvQLtzWmUvzNtKqVUcYM',
      img: '/images/satellite.png'
    }
  ]

  return (
    <Root>
      {mapStyles.map(({ img, src }) => 
        <Btn key={src} variant="outlined" onClick={() => setStyle(src)}>
          <img src={img} alt="asd"/>
        </Btn>
      )}
    </Root>
  )
}

export default MapStylesSwitcher
