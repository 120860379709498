import React from 'react'

const RequestIcon = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1666 13.3333C13.7083 13.3333 13.3333 12.9583 13.3333 12.5H6.66663C6.66663 12.9583 6.29163 13.3333 5.83329 13.3333C5.37496 13.3333 4.99996 12.9583 4.99996 12.5H1.66663V15C1.66663 15.9167 2.41663 16.6667 3.33329 16.6667H16.6666C17.5833 16.6667 18.3333 15.9167 18.3333 15V12.5H15C15 12.9583 14.625 13.3333 14.1666 13.3333Z" fill="#555F6D"/>
        <path d="M16.6666 6.66671H14.1666V5.00004C14.1666 4.08337 13.4166 3.33337 12.5 3.33337H7.49996C6.58329 3.33337 5.83329 4.08337 5.83329 5.00004V6.66671H3.33329C2.41663 6.66671 1.66663 7.41671 1.66663 8.33337V11.6667H4.99996V10.8334C4.99996 10.375 5.37496 10 5.83329 10C6.29163 10 6.66663 10.375 6.66663 10.8334V11.6667H13.3333V10.8334C13.3333 10.375 13.7083 10 14.1666 10C14.625 10 15 10.375 15 10.8334V11.6667H18.3333V8.33337C18.3333 7.41671 17.5833 6.66671 16.6666 6.66671ZM12.5 6.66671H7.49996V5.00004H12.5V6.66671Z" fill="black" fillOpacity="0.54"/>
      </svg>

    )
}

export default RequestIcon;