import { styled } from '@mui/material/styles'

export const PageWrapper = styled('div')`
  height: 100%;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`