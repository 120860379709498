import React from 'react'
import { PropTypes } from 'prop-types';

import { StyledStatusCircle } from './styled'

const StatusCircle = ({ children, ...props}) => {
  return <StyledStatusCircle {...props} >{children}</StyledStatusCircle>
}

StatusCircle.propTypes = {
  background: PropTypes.string,
  borderColor: PropTypes.string
}

export default StatusCircle;