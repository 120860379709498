import React from 'react'

const WarningIcon = () => {
    return (
			<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99975 1.49329L12.0198 10.1666H1.97975L6.99975 1.49329ZM0.826422 9.49995C0.313089 10.3866 0.953088 11.5 1.97975 11.5H12.0198C13.0464 11.5 13.6864 10.3866 13.1731 9.49995L8.15309 0.826621C7.63975 -0.0600455 6.35975 -0.0600455 5.84642 0.826621L0.826422 9.49995ZM6.33309 4.83329V6.16662C6.33309 6.53329 6.63309 6.83329 6.99975 6.83329C7.36642 6.83329 7.66642 6.53329 7.66642 6.16662V4.83329C7.66642 4.46662 7.36642 4.16662 6.99975 4.16662C6.63309 4.16662 6.33309 4.46662 6.33309 4.83329ZM6.33309 8.16662H7.66642V9.49995H6.33309V8.16662Z" fill="#F59638"/>
      </svg>


    )
}

export default WarningIcon;