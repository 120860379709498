import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@mui/material'

import { setMapFilterBy, setMapSelectedFilters, resetMapSelectedFilters } from '../../store/slices/fleet-map/slice'
import { setListFilterBy, setListSelectedFilters, resetListSelectedFilters } from '../../store/slices/fleet-list/slice'
import { colorForFilters } from '../../core'
import { Button, AddBikeForm } from '../../components'
import Badge from '../badge'
import StatusCircle from '../status-circle'
import DrawnerSwitcher from './drawner-switcher'
import { 
  HeaderWrapper,
  HeaderTopRow,
  HeaderCation,
  ViewFilterWrapper,
  ViewFilterItemWrapper,
  ViewSwitcherLabel,
  ViewSwitcherButton,
  ViewFilterItem,
  ViewFilterItemLabel,
} from './styled'

const FleetHeader = () => {
  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const [isCreateBikeModalOpen, setIsCreateBikeModalOpen] = useState(false)

  const { t } = useTranslation()

  const storeByPathname = {
    '/fleet/map': {
      store: 'fleetMap',
      setFilters: setMapFilterBy,
      setSelectedFilters: setMapSelectedFilters,
      resetSelectedFilters: resetMapSelectedFilters,
      title: t("fleetMap.headerTitle"),
    },
    '/fleet/list': {
      store: 'fleetList',
      setFilters: setListFilterBy,
      setSelectedFilters: setListSelectedFilters,
      resetSelectedFilters: resetListSelectedFilters,
      title: t("fleetList.headerTitle"),
    },
  }
  const { filterBy, filters, selectedFilters, counts } = useSelector(state => state[storeByPathname[pathname].store]);

  const filtersNames = ['subscription_type', 'status'];

  const changeFilterByHandler = (filter) => dispatch(storeByPathname[pathname].setFilters(filter));

  const setSelectedFilterHandler = (filter) => dispatch(storeByPathname[pathname].setSelectedFilters(filter));

  return (
    <HeaderWrapper>
      <HeaderTopRow>
        <HeaderCation>
          {pathname === '/fleet/map' && <DrawnerSwitcher/>}
          {storeByPathname[pathname].title}
        </HeaderCation>
        {
          pathname === '/fleet/list' && (
            <>
              <Button onClick={() => setIsCreateBikeModalOpen(true)} variant="outlined" size="small">{t('buttons.addBike')}</Button>
              <Dialog open={isCreateBikeModalOpen} onClose={() => setIsCreateBikeModalOpen(false)} fullWidth maxWidth="md">
                <AddBikeForm onCancel={() => setIsCreateBikeModalOpen(false)} />
              </Dialog>
            </>
          )
        }
      </HeaderTopRow>
      <ViewFilterWrapper>
        <ViewFilterItemWrapper>
          <ViewSwitcherLabel>
            {t('fleetHeader.filterSwitcherTitle')}
          </ViewSwitcherLabel>
          {filtersNames?.map((filter, index) => 
            <ViewSwitcherButton
              key={filter}
              isFirst={index === 0}
              isLast={filtersNames?.length - 1 === index}
              isActive={filterBy === filter}
              onClick={() => changeFilterByHandler(filter)}
            >
              {t(`fleetHeader.filterNames.${filter}`)}
            </ViewSwitcherButton>
          )}
          
          <ViewFilterItemWrapper >
              <ViewFilterItem onClick={() => dispatch(storeByPathname[pathname].resetSelectedFilters())}>
                <ViewFilterItemLabel background="#fff" borderColor="#CFD6DD">
                  All
                </ViewFilterItemLabel>
                <Badge
                  className='badge'
                  borderColor="#E9ECEF"
                  background='#E9ECEF'
                >
                  {counts[filterBy] && Object.values(counts[filterBy]).reduce((acc, item) => acc+=item)}
                </Badge>
              </ViewFilterItem>
            </ViewFilterItemWrapper>
          {filters?.map((filter => {

            const isFilterSelected = selectedFilters.includes(filter);

            return (
              <ViewFilterItemWrapper key={filter}>
                <ViewFilterItem selected={isFilterSelected} onClick={() => setSelectedFilterHandler(filter)}>
                  <ViewFilterItemLabel>
                    <StatusCircle background={colorForFilters[filterBy][filter]}>
                      {t(`units.${filterBy === 'status' ? 'statuses' : 'subscriptions'}.${filter}`)}
                    </StatusCircle>
                  </ViewFilterItemLabel>
                  <Badge
                    lassName='badge'
                    borderColor={isFilterSelected ? '#FFF' : '#E9ECEF'}
                    background={isFilterSelected ? '#FFF' : '#E9ECEF'}
                  >
                    {counts[filterBy] && counts[filterBy][filter]}
                  </Badge>
                </ViewFilterItem>
              </ViewFilterItemWrapper>
            )
          }
          ))}
        </ViewFilterItemWrapper>
      </ViewFilterWrapper>
    </HeaderWrapper>
  )
}

export default FleetHeader;