import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchCompanies = createAsyncThunk(
  'fetchCompanies',
  async () => {
    const response = await axiosClient.get('/companies/');
    return response.data.results;
  }
)

const initialState = {
  companies: [],
}

export const slice = createSlice({
  name: 'companies',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload
    })
  }
})

export {
  fetchCompanies,
}

export default slice.reducer;
