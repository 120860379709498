const ALL = 'ALL'
const CREATED = 'CREATED'
const APPROVED = 'APPROVED'
const IN_PROGRESS = 'IN_PROGRESS'
const RESOLVED = 'RESOLVED'
const CANCELLED = 'CANCELLED'

export const requestStatuses = {
  ALL,
  CREATED,
  APPROVED,
  IN_PROGRESS,
  RESOLVED,
  CANCELLED,
}

export const CircleColorsByStatus = {
  [CREATED] : {
      borderColor: '#79BCF6',
      background: '#fff'
  },
  [APPROVED] : {
      borderColor: '',
      background: '#79BCF6'
  },
  [IN_PROGRESS] : {
      borderColor: '',
      background: '#FEB872'
  },
  [RESOLVED] : {
      borderColor: '',
      background: '#75CC9E'
  },
  [CANCELLED] : {
      borderColor: '',
      background: '#F49090'
  },
}
