import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { Layout, ProtectedRoute } from './components'
import { 
	Login, 
	FleetList, 
	FleetMap,
	Requests,
	Request,
	Dashboard,
	Unit
 } from './pages'


const App = () => {
    return (
        <BrowserRouter>
					<Routes>
					<Route
							path='/dashboard'
							element={
									<ProtectedRoute>
										<Layout>
											<Dashboard />
										</Layout>
									</ProtectedRoute>
								}
						/>
						<Route
							path="/fleet"
							element={
							<ProtectedRoute>
								<Layout/>
							</ProtectedRoute>
							}
						>
							<Route 
								path="map"
								element={
									<ProtectedRoute>
										<FleetMap/>
									</ProtectedRoute>
								}
							/>
							<Route 
								path="list"
								element={
									<ProtectedRoute>
										<FleetList/>
									</ProtectedRoute>
								}
							/>
						</Route>
						<Route
							path='/requests'
							element={
									<ProtectedRoute>
										<Layout>
											<Requests />
										</Layout>
									</ProtectedRoute>
								}
						/>
						<Route
							path='/request/:id'
							element={
									<ProtectedRoute>
										<Layout>
											<Request />
										</Layout>
									</ProtectedRoute>
								}
						/>
						<Route
							path='/unit/:id/:tab'
							element={
									<ProtectedRoute>
										<Layout>
											<Unit />
										</Layout>
									</ProtectedRoute>
								}
						/>
						<Route path="/" element={<Login/>} />
					</Routes>
        </BrowserRouter>
    );
}

export default App;
