import { chargeStatuses } from '../constants'

export const getChargeStatus = (charge) => {
  if(charge === null){
    return chargeStatuses.MISSING
  }
  const intCharge = parseInt(charge);
  if(intCharge <= 20){
    return chargeStatuses.LOW
  } else if(intCharge <= 50){
    return chargeStatuses.MEDIUM
  } else {
    return chargeStatuses.HIGH
  }
}

 