import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'
import { FleetFilters } from '../../../core/constants'

const fetchListUnits = createAsyncThunk(
  'fetchListUnits',
  async ({ filters }) => {

    const units = await axiosClient.get('/bikes/', {
      params: {
        ...filters,
      }
    })
    
    return units.data
  }
)

const fetchListUnitsCounts = createAsyncThunk(
  'fetchListUnitsCounts',
  async () => {

    const statusCount = await axiosClient.get('/bikes/count/status/');

    const subscriptionCount = await axiosClient.get('/bikes/count/subscription/');

    return {
      status: statusCount.data,
      subscription_type: subscriptionCount.data,
  }
})

const initialState = {
  filterBy: 'subscription_type',
  filters: FleetFilters['subscription_type'],
  selectedFilters: [],
  listItems: {
    items: [],
    page: 1,
    page_size: 10,
    count: 0
  },
  counts: {
    status: null,
    subscription_type: null
  },
}

export const slice = createSlice({
  name: 'fleet-list',

  initialState,

  reducers: {
    setListFilterBy(state, { payload }) {
      state.filterBy = payload
      state.selectedFilters = []
      state.filters = FleetFilters[payload]
    },
    setListSelectedFilters(state, { payload }){
      if(state.selectedFilters.includes(payload)){
        state.selectedFilters = state.selectedFilters.filter(filter => filter !== payload)
      } else {
        state.selectedFilters.push(payload)
      }
    },
    resetListSelectedFilters(state){
      state.selectedFilters = []
    },
    setListItems(state, { payload }){
      state.listItems = {
        ...state.listItems,
        ...payload
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchListUnits.fulfilled, (state, { payload: { count, results} }) => {
      state.listItems = {
        ...state.listItems,
        items: results,
        count
      }
    })

    builder.addCase(fetchListUnitsCounts.fulfilled, (state, { payload }) => {
      state.counts.status = payload.status;
      state.counts.subscription_type = payload.subscription_type;
    })
  }
})

const {
  setListFilterBy,
  setListSelectedFilters,
  resetListSelectedFilters,
  setListItems
} = slice.actions

export {
  setListFilterBy,
  setListSelectedFilters,
  resetListSelectedFilters,
  fetchListUnits,
  setListItems,
  fetchListUnitsCounts
}

export default slice.reducer
