import React from 'react'

const ImageIcon = () => {
    return (
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.800065 2.99999C0.800065 1.78496 1.78504 0.799988 3.00007 0.799988H13.0001C14.2151 0.799988 15.2001 1.78496 15.2001 2.99999V10.1984C15.2001 10.1995 15.2001 10.2007 15.2001 10.2019V13C15.2001 14.215 14.2151 15.2 13.0001 15.2H3.00007C1.78504 15.2 0.800065 14.215 0.800065 13V10.0715C0.800043 10.0685 0.800043 10.0655 0.800065 10.0625V2.99999ZM2.00007 10.3494V13C2.00007 13.5523 2.44778 14 3.00007 14H13.0001C13.5524 14 14.0001 13.5523 14.0001 13V10.4607L11.5378 8.16261L9.34232 9.62626L11.0326 11.3841C11.2622 11.623 11.2548 12.0028 11.0159 12.2325C10.7771 12.4622 10.3972 12.4547 10.1676 12.2159L5.55306 7.41677L2.00007 10.3494ZM8.49727 8.74741L6.03257 6.18412C5.8151 5.95796 5.4601 5.93753 5.21813 6.13725L2.00007 8.79343V2.99999C2.00007 2.4477 2.44778 1.99999 3.00007 1.99999H13.0001C13.5524 1.99999 14.0001 2.4477 14.0001 2.99999V8.81926L12.0095 6.96135C11.806 6.77149 11.4988 6.74641 11.2672 6.90076L8.49727 8.74741Z" fill="#555F6D"/>
      </svg>

    )
}

export default ImageIcon