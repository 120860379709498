import React from 'react'

const BackArrowIcon = () => {
    return (
			<svg style={{ cursor: 'pointer' }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.264 11.2039C14.5657 11.4877 14.58 11.9624 14.2962 12.264L11.4858 15.25H22.25C22.6642 15.25 23 15.5858 23 16C23 16.4142 22.6642 16.75 22.25 16.75H11.4858L14.2962 19.736C14.58 20.0376 14.5657 20.5123 14.264 20.7962C13.9624 21.08 13.4877 21.0657 13.2038 20.764L9.20385 16.514C8.93205 16.2252 8.93205 15.7748 9.20385 15.486L13.2038 11.236C13.4877 10.9343 13.9624 10.92 14.264 11.2039Z" fill="#7E8B99"/>
        <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#CFD6DD"/>
      </svg>
    )
}

export default BackArrowIcon;