import React from 'react'

const ResetIcon = () => {
    return (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fillRule="evenodd" clipRule="evenodd" d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM5.21967 5.21967C5.51256 4.92678 5.98744 4.92678 6.28033 5.21967L9 7.93934L11.7197 5.21967C12.0126 4.92678 12.4874 4.92678 12.7803 5.21967C13.0732 5.51256 13.0732 5.98744 12.7803 6.28033L10.0607 9L12.7803 11.7197C13.0732 12.0126 13.0732 12.4874 12.7803 12.7803C12.4874 13.0732 12.0126 13.0732 11.7197 12.7803L9 10.0607L6.28033 12.7803C5.98744 13.0732 5.51256 13.0732 5.21967 12.7803C4.92678 12.4874 4.92678 12.0126 5.21967 11.7197L7.93934 9L5.21967 6.28033C4.92678 5.98744 4.92678 5.51256 5.21967 5.21967Z" fill="#4A545E"/>
      </svg>



    )
}

export default ResetIcon