import { styled } from '@mui/material/styles'

export const Aside = styled('aside')`
  width: 220px;
  border-right: 1px solid #E9ECEF; ;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
`

export const UserMenuItem = styled('div')`
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  padding: 8px;
  margin: 0 -8px;
  color: ${props => props.color || '#272E35'};
  display: flex;
  align-items: center;

  &:hover {
    background: #E9ECEF;
  }

  & svg {
    margin-right: 15px;
  }
`

export const AsideHeader = styled('header')`
  padding: 0px 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
`

export const MainUl = styled('ul')`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  & > li {
    padding: 8px 16px;

    &.link {
      padding: 4px 0;
    }

    & > a {

      border-radius: 5px;
      padding: 4px 16px 4px 4px;
      width: 100%;
      display: flex;
      align-items: center;

      &:hover {
        background: #E6EEFF;
      }

      &.active {
        background: #E6EEFF;
      }
    }
  }

  & svg {
    margin-right: 16px;
  }
`

export const SubUl = styled('ul')`
  margin: 0 -16px;
  padding-top: 16px;

  & > li {
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;

    & a {
      border-radius: 5px;
      padding: 4px 16px 4px 64px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #E6EEFF;
      }

      &.active {
        background: #E6EEFF;

        & .badge {
          border-color: #3062D4;
          background: #3062D4;
          color: #fff;
        }
      }
    }

  }
`

export const UserBlockWrapper = styled('div')`
  display: flex;
  margin-top: auto;
  cursor: pointer;
  align-items: center;
  padding: 0 16px;
`

export const UserAvatar = styled('div')`
  border-radius: 50%;
  height: 32px;
  color: #05205E;
  width: 32px;
  margin-right: 12px;
  background: #D7E4FF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  fonw-weight: 400;
  text-transform: uppercase;
`

export const UserTitle = styled('div')`
  font-size: 14px;
  fonw-weight: 400;
  line-height: 24px;
`
export const UserSubTitle = styled('div')`
  font-size: 14px;
  fonw-weight: 400;
  line-height: 24px;
  color: #7E8B99;
`