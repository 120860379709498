import React from 'react'

const FirstPageIcon = () => {
    return (
			<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.375001 1.40625C0.375001 1.04381 0.668814 0.75 1.03125 0.75C1.39369 0.75 1.6875 1.04381 1.6875 1.40625L1.6875 10.5938C1.6875 10.9562 1.39369 11.25 1.03125 11.25C0.668813 11.25 0.375 10.9562 0.375 10.5938L0.375001 1.40625Z" fill="#4A545E"/>
        <path d="M8.45312 0.931549C8.7153 0.681295 9.1307 0.690956 9.38095 0.953126C9.63121 1.2153 9.62154 1.6307 9.35938 1.88095L5.04418 6L9.35938 10.119C9.62154 10.3693 9.63121 10.7847 9.38095 11.0469C9.1307 11.309 8.7153 11.3187 8.45312 11.0685L3.64062 6.4747C3.5109 6.35087 3.4375 6.17934 3.4375 6C3.4375 5.82066 3.5109 5.64913 3.64062 5.5253L8.45312 0.931549Z" fill="#4A545E"/>
      </svg>
    )
}

export default FirstPageIcon