import React from 'react'

const FleetIcon = () => {
    return (
			<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.15 5.33325L13.7333 1.43325C13.4917 0.774919 12.8667 0.333252 12.1667 0.333252H10.8333C10.375 0.333252 10 0.708252 10 1.16659C10 1.62492 10.375 1.99992 10.8333 1.99992H12.1667L13.3833 5.33325H9.375L9.075 4.49992H9.15C9.60833 4.49992 9.98333 4.12492 9.98333 3.66659C9.98333 3.20825 9.60833 2.83325 9.15 2.83325H6.66667C6.20833 2.83325 5.83333 3.20825 5.83333 3.66659C5.83333 4.12492 6.20833 4.49992 6.66667 4.49992H7.29167L8.80833 8.66658H8.25C7.88333 6.80825 6.325 5.43325 4.375 5.34159C2.04167 5.22492 0 7.16658 0 9.49992C0 11.8333 1.83333 13.6666 4.16667 13.6666C6.21667 13.6666 7.875 12.2583 8.25 10.3333H11.75C12.1167 12.1916 13.675 13.5666 15.625 13.6583C17.9583 13.7666 20 11.8333 20 9.49158C20 7.15825 18.1667 5.32492 15.8333 5.32492H15.15V5.33325ZM6.51667 10.3333C6.16667 11.3583 5.18333 12.0666 4 11.9916C2.75833 11.9166 1.725 10.8666 1.66667 9.61659C1.60833 8.15825 2.725 6.99992 4.16667 6.99992C5.275 6.99992 6.18333 7.69159 6.51667 8.66658H5C4.54167 8.66658 4.16667 9.04158 4.16667 9.49992C4.16667 9.95825 4.54167 10.3333 5 10.3333H6.51667ZM11.75 8.66658H10.5833L9.975 6.99992H12.5C12.1333 7.48325 11.8667 8.04158 11.75 8.66658ZM15.7333 11.9999C14.45 11.9499 13.3667 10.8583 13.3333 9.56658C13.3167 8.76659 13.6583 8.06659 14.2083 7.59992L14.725 9.01659C14.8833 9.44992 15.3583 9.67492 15.7917 9.51659C16.225 9.35825 16.45 8.88325 16.2917 8.44992L15.7667 7.00825C15.7667 7.00825 15.7667 7.00825 15.775 6.99992C17.2083 6.96659 18.3417 8.07492 18.3417 9.49992C18.3333 10.9333 17.1833 12.0499 15.7333 11.9999Z" fill="black" fillOpacity="0.54"/>
			</svg>
    )
}

export default FleetIcon;