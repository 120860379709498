import { styled } from '@mui/material/styles'

export const PreviewImage = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.image});
  background-size: cover;
  display: ${props => props.hidden ? "none" : "flex"}
`