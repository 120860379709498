import { styled } from '@mui/material/styles'

export const PageWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 150px);
`

export const TableContainer = styled('div')`
  padding: 12px;
  flex-grow: 1;
  max-height: 100%;
`

export const StatusCellInner = styled('div')`
  font-weight: 450;
  font-size: 12px;
  line-height: 20px;
  color: #202532;
  display: inline-block;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props => props.color}
  }
`

export const StatusTimer = styled('div')`
  margin-left: 10px;
  font-weight: 450;
  font-size: 10px;
  line-height: 16px;
  color: #555F6D;
`

export const ChargeCellInner = styled('div')`
  color: ${props => props.color};
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;

  & svg {
    margin-right: 2px;
    margin-bottom: 1px;
    display: inline-block;
  }
`