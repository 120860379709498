import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Dialog } from '@mui/material'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import maplibregl from 'maplibre-gl'
import { format } from 'date-fns'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import countries from "i18n-iso-countries"

import { 
  BackArrowIcon,
  CopyIcon,
  Button,
  StatusCircle,
  Text,
  Gallery,
  Video,
  ImageIcon,
  VideoIcon,
  TabPanel,
  FileDownloadIcon,
  MapMarkerIcon,
  MapExpandIcon,
  MapCloseIcon,
  CommonTable,
  CreateRequestForm,
  DatePopup
 } from '../../components'
import {
  fetchBike,
  fetchBikeClientHistory,
  fetchBikeRequests,
  resetBike,
  setBikeClientHistory,
  setBikeRequests
} from '../../store/slices/bikes/slice'
import {
  getDateString,
  CircleColorsByStatus,
  ChargeIconByCharge,
  copyToClipboard,
  getChargeStatus,
  getTTF,
  fileTypes,
  normalizeAttachments,
  normalizeDocuments,
  colorForFilters,
  getFileExtention,
  getFileSize
} from '../../core'
import DrawnerUnit from '../fleet-map/drawner-unit'
import {
  Header,
  PageWrapper,
  ContentWrapper,
  Preview,
  TabsBody,
  TabButton,
  Devider,
  MapWrapper,
  ExpandButtonWrapper,
  MapCloseButton,
  HistoryTableContainer,
  OdTTF,
  TableContainer,
  DatePopupWrapper
} from './styled'

const Unit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id, tab: currentTab } = useParams()
  const dispatch = useDispatch()
  const { 
    bike,
    bikeClientHistory: { items: bikeClientHistory, page: historyPage, page_size: historyPageSize},
    bikeRequests: { items: bikeRequests, page: requestPage, page_size: requestPageSize}
   } = useSelector(state => state.bikes)
   
  const defaultDateValue = {
    label: t("date.today"),
    value: {
      date_from: format(new Date(), 'yyyy-MM-dd'),
      date_to: format(new Date(), 'yyyy-MM-dd'),
    }
  }
  const [dateForFilter, setDateForFilter] = useState(defaultDateValue)
  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] = useState(false)
  const [isVideosModalOpen, setIsVideosModalOpen] = useState(false)
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false)
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)
  const [isMapPopupOpen, setIsMapPopupOpen] = useState(false)
  const [isMapModalPopupOpen, setIsMapModalPopupOpen] = useState(false)
  const [mapClickLocation, setMapClickLocation] = useState()
  const [modalMapClickLocation, setModalMapClickLocation] = useState()
  const [viewport, setViewport] = useState({
    zoom: 1,
    height: "100%",
    width: "100%"
  })
  const [trackViewport, setTrackViewport] = useState({
    zoom: 8,
    height: "100%",
    latitude: bike?.location?.lat || null,
    longitude: bike?.location?.lon || null,
    width: "100%"
  })
  const mapRef = useRef(null)
  const trackMapRef = useRef(null)
  
  const renderVideo = (item) => <Video {...item} />
  const attachments = useMemo(() =>  normalizeAttachments(bike?.attachments) || {}, [bike])
  const documents = useMemo(() =>  normalizeDocuments(bike?.attachments) || {}, [bike])

  const tabs = ['details', 'serviceRequests','travelHistory', 'clientHistory']
  const countryNames = useMemo(() => countries.getNames('en'), [])

  const requestsColumns = [
    {
      headCell: t("requests.tableHeadCells.created"),
      getValue: (row) => <>
        <Text nowrap margin="0 0 -5px 0" display="flex" fontWeight="600" >{getDateString(row.request.created)}</Text>
        <Text nowrap fontWeight="450" fontSize="10px ">{row.request.uid}</Text>
      </>
    },
    {
      headCell: t("requests.tableHeadCells.status"),
      getValue: (row) => 
        <Text>
          <StatusCircle {...CircleColorsByStatus[row.status]}>{t(`requests.statuses.${row.status}`)}</StatusCircle>
        </Text>
    },
    {
      headCell: t("requests.tableHeadCells.ttf"),
      getValue: (row) => {
        const ttf = getTTF(row.ttf)
        if(ttf === 'OD'){
          return <OdTTF>{ttf}</OdTTF>
        }
        return ttf
      }
    },
    {
      headCell: t("requests.tableHeadCells.client"),
      getValue: (row) => row.client
    },
    {
      headCell: t("requests.tableHeadCells.issue"),
      getValue: (row) => row.issue
    },
    {
      headCell: t("requests.tableHeadCells.assignee"),
      getValue: (row) => row.assignee
    },
  ]

  const historyColumns = [
    {
      
      headCell: t("unit.historyHeadCells.rentPeriod"),
      getValue: (row) => row.rentPeriod
    },
    {
      
      headCell: t("unit.historyHeadCells.client"),
      getValue: (row) => row.client
    }
  ]

  const historyTableData = useMemo(() => bikeClientHistory?.map(historyItem => ({
    rentPeriod: `${getDateString(new Date(historyItem?.begin_dt))} — ${historyItem?.end_dt ? getDateString(new Date(historyItem?.end_dt)) : 'current'}`,
    client: historyItem?.client?.name
  })),[bikeClientHistory])
  
  const requestsTableData = useMemo(() => bikeRequests?.map(request => ({
    request: {
      created: new Date(request?.created),
      uid: request?.uid,
      id: request?.id,
    },
    unit: {
      id: request?.unit?.uid,
      status: request?.unit?.status
    },
    status: request?.status,
    ttf: request?.time_to_fix,
    client: request?.client?.name,
    issue: t(`problemTypes.${request?.issue}`),
    assignee: request.assignee_id ? `${request?.assignee?.first_name || ''} ${request?.assignee?.last_name || ''}` : t('other.unassigned'),
    rowBg: getTTF(request.time_to_fix) === 'OD' ? '#FFFAFA' : 'initial'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [bikeRequests])

  useEffect(() => {
    dispatch(fetchBike({ id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    dispatch(fetchBikeRequests({ id, page: requestPage, page_size: requestPageSize }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPage, requestPageSize])
  useEffect(() => {
    dispatch(fetchBikeClientHistory({ id, page: historyPage, page_size: historyPageSize }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyPage, historyPageSize])
  useEffect(() => {
    if(bike?.location?.lat && bike?.location?.lon){
      setViewport({
        latitude:  bike?.location?.lat,
        longitude: bike?.location?.lon,
        zoom: 10,
        height: "100%",
        width: "100%"
      })
    }
  }, [bike])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(resetBike()), [])

  useEffect(() => {
    const map = trackMapRef.current?.getMap()
    if(map){ 
      map.getSource('route').setData(`https://app.swappz.co/api/v1/bikes/${id}/location-history/geojson/?date_from=${dateForFilter.value.date_from}&date_to=${dateForFilter.value.date_to}&only_lines=1`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForFilter])

  useEffect(() => {
    setDateForFilter(defaultDateValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  useEffect(() => setTrackViewport(prev => ({ ...prev, latitude: bike?.location?.lat || null, longitude: bike?.location?.lon || null })), [bike])

  if(!bike){
    return null
  }

  return (
    <PageWrapper>
      <Header>
        <Grid container spacing={2} marginBottom={3} alignItems="center">
            <Grid item xs="auto" onClick={() => navigate(state?.backpath ? state.backpath : '/fleet/list', { state })}>
              <BackArrowIcon />
            </Grid>
            <Grid item xs="auto">
              <Text fontWeight="600" fontSize="18px" color="#272E35">
                {t('unit.headerTitle')}&nbsp;
                {bike?.uid}
              </Text>
            </Grid>
            <Grid item xs="auto" onClick={() => copyToClipboard(bike?.uid)}>
              <CopyIcon />
            </Grid>
            <Grid marginLeft="auto" item xs='auto'>
              <Button onClick={() => setIsCreateRequestModalOpen(true)} variant="outlined" size="small">{t('buttons.createRequest')}</Button>
            </Grid>
        </Grid>
        <Grid container rowSpacing={0} columnSpacing={4}>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.status')}</Text>
            <Grid container rowSpacing={0} columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <Text fontWeight="500">
                    <StatusCircle background={colorForFilters.status[bike?.status]}>{t(`units.statuses.${bike?.status}`)}</StatusCircle>
                    </Text>
                  </Grid>
                  <Grid item xs="auto" display="flex" alignItems="center">
                    {ChargeIconByCharge[getChargeStatus(bike?.charge)]}
                    <Text color="#1D7C4D" fontSize="12px" fontWeight="500" margin="0 0 0 3px">&nbsp;{bike?.charge ? `${parseInt(bike?.charge)}%` : '—'}</Text>
                  </Grid>
                </Grid>
          </Grid>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.plan')}</Text>
            <Text fontSize="16px" fontWeight="500">{t(`units.subscriptions.${bike?.subscription_type}`)}</Text>
          </Grid>
          {
            bike?.rta_plate_number && (
              <Grid item xs="auto" display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.plate')}</Text>
                <Text fontSize="16px" fontWeight="500">{bike?.rta_plate_number}</Text>
              </Grid>
            )
          }
        </Grid>
      </Header>
        <TabsBody>
          <Grid container rowSpacing={0} columnSpacing={3}>
            {tabs.map(tab => (
              <Grid key={tab} item xs="auto">
                <TabButton isActive={currentTab === tab} onClick={() => navigate(`/unit/${id}/${tab}`)}>
                  {t(`unit.tabs.${tab}`)}
                </TabButton>
              </Grid>
            ))}
          </Grid>
        </TabsBody>
      <ContentWrapper padding={currentTab === 'travelHistory' ? 0 : '24px'}>
        <TabPanel tab="details" currentTab={currentTab}>
          <Grid container rowSpacing={0} columnSpacing={4} marginBottom="auto">
            <Grid item xs={8}>
              <Grid container rowSpacing={0} columnSpacing={4} flexWrap="wrap">
                <Grid item xs={6}>
                  <Grid container rowSpacing={0} columnSpacing={0} flexDirection="column">
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.client')}</Text>
                      <Text fontSize="16px" fontWeight="500">{bike?.client?.name || <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />}</Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.rider')}</Text>
                      <Text fontSize="16px" fontWeight="500">
                        {bike?.courier?.first_name  || <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />} {bike?.courier?.last_name  || <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                 <Grid container rowSpacing={0} columnSpacing={0} flexDirection="column">
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.model')}</Text>
                      <Text fontSize="16px" fontWeight="500">{bike?.bike_model?.name}</Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.battery')}</Text>
                      <Text fontSize="16px" fontWeight="500">{bike?.battery?.uid || <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />}</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Devider />
              <Grid container rowSpacing={0} columnSpacing={4} flexWrap="wrap">
                <Grid item xs={6}>
                  <Grid container rowSpacing={0} columnSpacing={0} flexDirection="column">
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.owner')}</Text>
                      <Text fontSize="16px" fontWeight="500">{bike?.owner?.company?.name}</Text>
                    </Grid>
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.supplier')}</Text>
                      <Text fontSize="16px" fontWeight="500">{bike?.supplier?.name}</Text>
                    </Grid>
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.receivingDate')}</Text>
                      <Text fontSize="16px" fontWeight="500">{getDateString(new Date(bike?.received_date))}</Text>
                    </Grid>
                    {(attachments[fileTypes.IMAGE] || attachments[fileTypes.VIDEO]) && (
                      <Grid item xs={12}>
                        <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D" margin="0 0 10px 0">{t('unit.blocksTitles.media')}</Text>
                        <Grid container rowSpacing={0} columnSpacing={3} marginBottom={4}>
                          {attachments[fileTypes.IMAGE] && (
                            <Grid xs="auto" item display="flex" flexDirection="column">
                              <Preview onClick={() => setIsImagesModalOpen(true)} image={attachments[fileTypes.IMAGE][0]} />
                              <Grid container rowSpacing={0} columnSpacing={0.5} alignItems="center" marginTop={0.5}>
                                <Grid xs="auto" item display="flex" alignItems="center">
                                  <ImageIcon />
                                </Grid>
                                <Grid xs="auto" item>
                                    <Text fontSize="12px" fontWeight="500">{attachments[fileTypes.IMAGE].length}</Text>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {attachments[fileTypes.VIDEO] && (
                            <Grid xs="auto" item display="flex" flexDirection="column">
                              <Preview onClick={() => setIsVideosModalOpen(true)} image="/images/video-preview.jpg" />
                              <Grid container rowSpacing={0} columnSpacing={0.5} alignItems="center" marginTop={0.5}>
                                <Grid xs="auto" item display="flex" alignItems="center">
                                  <VideoIcon />
                                </Grid>
                                <Grid xs="auto" item>
                                    <Text fontSize="12px" fontWeight="500">{attachments[fileTypes.VIDEO].length}</Text>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                <Grid container rowSpacing={0} columnSpacing={0} flexDirection="column">
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.country')}</Text>
                      <Text fontSize="16px" fontWeight="500">{countryNames[bike?.country]}</Text>
                    </Grid>
                    <Grid item xs={12} marginBottom={2.5}>
                      <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D">{t('unit.blocksTitles.hub')}</Text>
                      <Text display="flex" fontSize="16px" fontWeight="500" >{bike?.client_hub?.name || <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />}</Text>
                      <Text fontSize="14px" fontWeight="400">{bike?.client_hub?.address}</Text>
                    </Grid>
                      {documents.length && (
                        <Grid item xs={12} marginBottom={2.5}>
                          <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D" margin="0 0 5px 0">{t('unit.blocksTitles.documents')}</Text>
                          {documents.map(document=> {
                            return (
                              <Grid container rowSpacing={0} columnSpacing={1} alignItems="center" marginBottom={1}>
                                <Grid item xs="auto">
                                  <Text fontSize="16px" fontWeight="500">{document.original_filename.split('.')[0]}</Text>
                                  <Text display="flex" fontSize="10px" fontWeight="500" color="#555F6D" >{getFileExtention(document.original_filename)}  {getFileSize(document.size)}</Text>
                                </Grid>
                                <Grid item xs="auto">
                                  <a target="_blank" rel="noreferrer" href={document.url} download><FileDownloadIcon /></a>
                                </Grid> 
                              </Grid>
                            )
                          })}
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <MapWrapper>
                <ExpandButtonWrapper disabled={!(bike?.location?.lat && bike?.location?.lon)}>
                  <Button variant="outlined" size="small" onClick={() => setIsMapModalOpen(true)}>
                    <MapExpandIcon />
                    <Text fontWeight="500" margin="2px 0 0 5px" color="#4A545E">
                      {t('unit.buttons.expand')}
                    </Text>
                  </Button>
                </ExpandButtonWrapper>
                  {
                    viewport && (
                      <ReactMapGL
                        mapLib={maplibregl}
                        {...viewport}
                        ref={mapRef}
                        clickRadius={25}
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        mapStyle="https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM"
                        onMove={(e) => {
                          setViewport(e.viewState)
                        }}
                        onMouseUp={(e) => {
                          if(mapClickLocation.x === e.point.x && mapClickLocation.y === e.point.y){
                            setIsMapPopupOpen(false)
                          }
                        }}
                        onMouseDown={e => setMapClickLocation({ x: e.point.x , y: e.point.y })}
                      >
                        {
                          isMapPopupOpen && (
                              <Popup
                              maxWidth="auto"
                              latitude={bike?.location?.lat}
                              longitude={bike?.location?.lon}
                              closeButton={false}
                              closeOnClick={false}
                              onClose={() => setIsMapPopupOpen(false)}
                            >
                                <DrawnerUnit
                                  key={bike?.id}
                                  uid={bike?.uid}
                                  charge={bike?.charge}
                                  status={bike?.status}
                                  subscriptionType={bike?.subscription_type}
                                  rtaPlateNumber={bike?.rta_plate_number}
                                  modelName={bike?.bike_model.name}
                                  isLast
                                />
                            </Popup>
                          )
                        }
                        {
                          bike?.location?.lat && bike?.location?.lon && (
                            <Marker
                              onClick={() => setIsMapPopupOpen(true)}
                              latitude={bike?.location?.lat}
                              longitude={bike?.location?.lon}
                            >
                              <MapMarkerIcon
                                color={colorForFilters.status[bike?.status]}
                              />
                            </Marker>
                          )
                          
                        }
                      </ReactMapGL>
                    )
                  }
              </MapWrapper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel tab="serviceRequests" currentTab={currentTab}>
          <TableContainer>
            <CommonTable 
              columns={requestsColumns}
              data={[...requestsTableData, ...requestsTableData]}
              onRowClick={(row) => navigate(`/request/${row.request.id}`, { state: { backpath: `/unit/${id}/${currentTab}`}})}
              pagination={{
                paginationSelector: state => state.bikes.bikeRequests,
                pagintaionSetter: props => dispatch(setBikeRequests(props))
              }}
            />
          </TableContainer>
        </TabPanel>
        <TabPanel tab="clientHistory" currentTab={currentTab}>
          <HistoryTableContainer>
            <CommonTable
              columns={historyColumns}
              data={historyTableData}
              pagination={{
                paginationSelector: state => state.bikes.bikeClientHistory,
                pagintaionSetter: props => dispatch(setBikeClientHistory(props))
              }}
            />
          </HistoryTableContainer>
        </TabPanel>
        <TabPanel tab="travelHistory" currentTab={currentTab}>
          <Grid container flexDirection="column" sx={{ height: '100%' }}>
            <Grid item flex={1}>
              <ReactMapGL
                mapLib={maplibregl}
                {...trackViewport}
                ref={trackMapRef}
                clickRadius={25}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                mapStyle="https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM"
                onMove={(e) => {
                  setTrackViewport(e.viewState)
                }}
                onLoad={() => {
                  const map = trackMapRef.current?.getMap()
                  map.addSource('route', {
                    'type': 'geojson',
                    'data': `https://app.swappz.co/api/v1/bikes/${id}/location-history/geojson/?date_from=${dateForFilter.value.date_from}&date_to=${dateForFilter.value.date_to}&only_lines=1`
                  });
                  map.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                    },
                    'paint': {
                    'line-color': '#297be4',
                    'line-width': 4
                    }
                    });
                }}
              >
                <DatePopupWrapper>
                  <DatePopup initialDate={dateForFilter} setDate={setDateForFilter} allTime={false} />
                </DatePopupWrapper>
              </ReactMapGL>
            </Grid>
          </Grid>
        </TabPanel>
      </ContentWrapper>
      <Dialog open={isImagesModalOpen} onClose={() => setIsImagesModalOpen(false)} fullWidth maxWidth="lg">
        <Gallery items={attachments[fileTypes.IMAGE]?.map((url) => ({ original: url }))} showPlayButton={false} showThumbnails={false} />
      </Dialog>
      <Dialog open={isVideosModalOpen} onClose={() => setIsVideosModalOpen(false)} fullWidth maxWidth="lg">
        <Gallery
          items={attachments[fileTypes.VIDEO]?.map(url => ({
            poster: '/images/video-preview.jpg',
            videoUrl: url,
            renderItem: renderVideo
          }))}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
     />
      </Dialog>
      <Dialog open={isCreateRequestModalOpen} onClose={() => setIsCreateRequestModalOpen(false)} fullWidth maxWidth="md">
        <CreateRequestForm
          onSuccess={() => {
            dispatch(fetchBikeRequests({ id }))
            navigate(`/unit/${id}/serviceRequests`)
          }}
          bikeId={Number(id)}
          onClose={() => setIsCreateRequestModalOpen(false)}
         />
      </Dialog>
      <Dialog open={isMapModalOpen} onClose={() => setIsMapModalOpen(false)} fullWidth maxWidth="lg">
        <div style={{ height: "90vh" }}>
          <MapCloseButton onClick={() => setIsMapModalOpen(false)}>
            <MapCloseIcon />
          </MapCloseButton>
          <ReactMapGL
            mapLib={maplibregl}
            {...viewport}
            ref={mapRef}
            clickRadius={25}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle="https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM"
            onMove={(e) => {
              setViewport(e.viewState)
            }}
            onMouseUp={(e) => {
              if(modalMapClickLocation.x === e.point.x && modalMapClickLocation.y === e.point.y){
                setIsMapModalPopupOpen(false)
              }
            }}
            onMouseDown={e => setModalMapClickLocation({ x: e.point.x , y: e.point.y })}
          >
            {
              isMapModalPopupOpen && (
                  <Popup
                  maxWidth="auto"
                  latitude={bike?.location?.lat}
                  longitude={bike?.location?.lon}
                  closeButton={false}
                  closeOnClick={false}
                  onClose={() => setIsMapModalPopupOpen(false)}
                >
                    <DrawnerUnit
                      key={bike?.id}
                      uid={bike?.uid}
                      charge={bike?.charge}
                      status={bike?.status}
                      subscriptionType={bike?.subscription_type}
                      rtaPlateNumber={bike?.rta_plate_number}
                      modelName={bike?.bike_model.name}
                      isLast
                    />
                </Popup>
              )
            }
            <Marker
              onClick={() => setIsMapModalPopupOpen(true)}
              latitude={bike?.location?.lat}
              longitude={bike?.location?.lon}
            >
              <MapMarkerIcon
                color={colorForFilters.status[bike?.status]}
              />
            </Marker>
          </ReactMapGL>
        </div>
      </Dialog>
    </PageWrapper>
  )
}


export default Unit