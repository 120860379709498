
 export const getSoketsUrl = () => {
  const baseUrl = process.env.REACT_APP_WEBSOKET_URL
  
  // проверяем на то, что путь в .env абсолютный или нет
  if(baseUrl[0] === 'w'){
    return baseUrl
  }
  let loc = window.location, new_uri;
  if (loc.protocol === "https:") {
      new_uri = "wss:";
  } else {
      new_uri = "ws:";
  }
  const WSUrl = `${new_uri}//${loc.host}${baseUrl}`
  return WSUrl
}