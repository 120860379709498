import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from "react-hook-form"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import * as yup from "yup"
import {
  Grid,
  MenuItem,
  FormControl,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import isoCountries from "i18n-iso-countries"


import { fetchListUnits, fetchListUnitsCounts } from '../../store/slices/fleet-list/slice'
import { fetchBikeModels } from '../../store/slices/bikes/slice'
import { fetchBatteries } from '../../store/slices/batteries/slice'
import { fetchOwnerships } from '../../store/slices/ownerships/slice'
import { fetchCompanies } from '../../store/slices/companies/slice'
import { fetchCountries } from '../../store/slices/countries/slice'
import {
  useYupValidationResolver,
  SubscriptionFilters,
  axiosClient
} from '../../core'
import { 
  Select,
  Text,
  PlusIcon,
  Button,
  TextInput,
  AddDocument
} from '../../components'
import { StyledForm } from './styled'

const AddBikeForm = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [plan, setPlan] = useState(null)
  const schema = yup.object().shape({
    uid: yup.string().required(t('errorMessages.required'))
    .test('len', 'Must be exactly 6 digits', val => val.length === 6)
    .test('digits', t('errorMessages.digitsOnly'), val => /^\d+$/.test(val)),
    tracker_sync_uid: yup.string()
    .test('digitsAndLetters', t('errorMessages.digitsAndLettersOnly'), val => /^[A-Za-z0-9]*$/.test(val))
    .required(t('errorMessages.required')),
    subscription_type: yup.mixed().required(t('errorMessages.required')),
    rta_plate_number: yup.string()
      .test('optionalRequired', t('errorMessages.required'), val => plan === "U" ? val?.length : true)
      .test('digitsAndLetters', t('errorMessages.digitsAndLettersOnly'), val => /^[A-Za-z0-9]*$/.test(val)),
    serial_number: yup.string().test('digitsAndLetters', t('errorMessages.digitsAndLettersOnly'), val => /^[A-Za-z0-9]*$/.test(val)),
    bike_model_id: yup.string().required(t('errorMessages.required')),
    owner_id: yup.string().required(t('errorMessages.required')),
    supplier_id: yup.string().required(t('errorMessages.required')),
    received_date: yup.mixed().required(t('errorMessages.required')),
    country: yup.string().required(t('errorMessages.required')),
  })
  const resolver = useYupValidationResolver(schema)
  const { bikesModels } = useSelector(state => state.bikes)
  const { ownerships } = useSelector(state => state.ownerships)
  const { batteries } = useSelector(state => state.batteries)
  const { companies } = useSelector(state => state.companies) 
  const { countries } = useSelector(state => state.countries)
  const { filterBy, selectedFilters } = useSelector(state => state.fleetList)
  const countryNames = useMemo(() => isoCountries.getNames('en'), [])
  const bikesModelsOptions = useMemo(() => bikesModels?.map(({ id, name }) => ({ value: id, label: name })) || [], [bikesModels])
  const companiesOptions = useMemo(() => companies?.map(({ id, name }) => ({ value: id, label: name })) || [], [companies])
  const batteriesOptions = useMemo(() => batteries?.map(({ uid, id }) => ({ value: id, label: uid })) || [], [batteries])
  const countriesOptions = useMemo(() => countries?.map(({ iso_code }) => ({ value: iso_code, label: countryNames[iso_code] })) || [], [countries, countryNames])
  const ownershipsOptions = useMemo(() => ownerships?.map(({ company: { name }, id }) => ({ value: id, label: name })) || [], [ownerships])
  const { 
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues
  } = useForm({
    defaultValues: {
      uid: '',
      tracker_sync_uid: '',
      rta_plate_number: '',
      serial_number: '',
      bike_model_id: '',
      battery_id: '',
      owner_id: '',
      supplier_id: '',
      country: '',
      attachment_ids: []
    },
    resolver,
  })
  const onSubmit = ({ received_date, battery_id, ...rest }) => {
    const proxy = {
      ...rest,
      received_date: format(new Date(received_date.$d), 'yyyy-MM-dd'),
      battery_id: battery_id || null
    }
    axiosClient.post('/bikes/', proxy)
    .then(() => {
      onCancel()
      dispatch(fetchListUnits({ filters:{
        [filterBy]: selectedFilters.join(',')
      }}))
      dispatch(fetchListUnitsCounts())
    })
    .catch(err => toast.error(err?.response?.data?.detail))
  }
  useEffect(() => {
    setPlan(watch('subscription_type'))
    if(watch('subscription_type') !== "U"){
      setValue('rta_plate_number', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('subscription_type')])
  useEffect(() => {
    dispatch(fetchBikeModels())
    dispatch(fetchBatteries({ filters: { in_use: false }}))
    dispatch(fetchCompanies())
    dispatch(fetchCountries())
    dispatch(fetchOwnerships())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.uid')}</Text>
            <Controller
              name="uid"
              control={control}
              render={({ field: { onChange, ...rest} }) => 
                <FormControl fullWidth>
                  <TextInput
                  placeholder={t('addBikeForm.placeholders.uid')}
                  width="100%"
                  {...rest}
                  onChange={event => {
                    if(event.target.value.length > 6) return
                    onChange({
                      ...event,
                      target: {
                        ...event.target,
                        value: event.target.value.replace(/[^0-9]/g,"")
                      }
                    })
                  }}
                  ref={null}/>
                </FormControl>
              }
            />
            <Text margin="5px 0 0 0" display="block" color="red">{errors?.uid?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.tracker')}</Text>
          <Controller
            name="tracker_sync_uid"
            control={control}
            render={({ field: { onChange, ...rest} }) => 
              <FormControl fullWidth>
                <TextInput
                  placeholder={t('addBikeForm.placeholders.typeHere')}
                  width="100%"
                  onChange={event => {
                    onChange({
                      ...event,
                      target: {
                        ...event.target,
                        value: event.target.value.replace(/[^a-zA-z0-9]+/g,"").toUpperCase()
                      }
                    })
                  }}
                  {...rest}
                  ref={null}
                />
              </FormControl>
            }
          />
          <Text margin="5px 0 0 0" display="block" color="red">{errors?.tracker_sync_uid?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.plan')}</Text>
            <Controller
              name="subscription_type"
              control={control}
              render={({ field }) => 
                <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.plan')
                      }
                      return t(`units.subscriptions.${selected}`)
                    }}
                    options={SubscriptionFilters}
                    renderOptions={(value) => (
                      <MenuItem key={value} value={value}>
                        {t(`units.subscriptions.${value}`)}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                />
                <Text margin="5px 0 0 0" display="block" color="red">{errors?.subscription_type?.message}</Text>
              </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.plate')}</Text>
            <Controller
              name="rta_plate_number"
              control={control}
              render={({ field: { onChange, ...rest } }) => 
                <FormControl fullWidth>
                  <TextInput
                    disabled={watch('subscription_type') !== "U"}
                    placeholder={t('addBikeForm.placeholders.typeHere')}
                    width="100%"
                    onChange={event => {
                      onChange({
                        ...event,
                        target: {
                          ...event.target,
                          value: event.target.value.replace(/[^a-zA-z0-9]+/g,"").toUpperCase()
                        }
                      })
                    }}
                    {...rest}
                    ref={null}
                  />
                </FormControl>
              }
            />
            <Text margin="5px 0 0 0" display="block" color="red">{errors?.rta_plate_number?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.serialNumber')}</Text>
            <Controller
              name="serial_number"
              control={control}
              render={({ field: { onChange, ...rest } }) => 
                <FormControl fullWidth>
                  <TextInput
                    placeholder={t('addBikeForm.placeholders.typeHere')}
                    width="100%"
                    onChange={event => {
                      onChange({
                        ...event,
                        target: {
                          ...event.target,
                          value: event.target.value.replace(/[^a-zA-z0-9]+/g,"").toUpperCase()
                        }
                      })
                    }}
                    {...rest}
                    ref={null}
                  />
                </FormControl>
              }
            />
            <Text margin="5px 0 0 0" display="block" color="red">{errors?.serial_number?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.model')}</Text>
            <Controller
              name="bike_model_id"
              control={control}
              render={({ field }) => 
                <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.model')
                      }
                      return bikesModelsOptions.find(({ value }) => value === selected)?.label
                    }}
                    options={bikesModelsOptions}
                    renderOptions={({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                />
                <Text margin="5px 0 0 0" display="block" color="red">{errors?.bike_model_id?.message}</Text>
              </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.battery')}</Text>
            <Controller
              name="battery_id"
              control={control}
              render={({ field }) => 
                <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.battery')
                      }
                      return batteriesOptions.find(({ value }) => value === selected).label
                    }}
                    options={batteriesOptions}
                    renderOptions={({ value , label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                  />
                  <Text margin="5px 0 0 0" display="block" color="red">{errors?.battery_id?.message}</Text>
                </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.owner')}</Text>
            <Controller
              name="owner_id"
              control={control}
              render={({ field }) => 
                <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.owner')
                      }
                      return ownershipsOptions.find(({ value }) => value === selected).label
                    }}
                    options={ownershipsOptions}
                    renderOptions={({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                  />
                  <Text margin="5px 0 0 0" display="block" color="red">{errors?.owner_id?.message}</Text>
                </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.supplier')}</Text>
            <Controller
              name="supplier_id"
              control={control}
              render={({ field }) => 
                <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.supplier')
                      }
                      return companiesOptions.find(({ value }) => value === selected).label
                    }}
                    options={companiesOptions}
                    renderOptions={({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                  />
                  <Text margin="5px 0 0 0" display="block" color="red">{errors?.supplier_id?.message}</Text>
                </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.receivingDate')}</Text>
          <Controller
            name="received_date"
            control={control}
            render={
                ({ field: { onChange, ...restField } }) =>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="smallDatePicker fullWidth"
                      onChange={(event) => {
                        onChange(event)
                      }}
                      {...restField}
                    />
                </LocalizationProvider>
              }
            /> 
            <Text margin="5px 0 0 0" display="block" color="red">{errors?.received_date?.message}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.country')}</Text>
            <Controller
              name="country"
              control={control}
              render={({ field }) => 
               <FormControl fullWidth>
                  <Select
                    paddingTop="8px"
                    paddingBottom="8px"
                    height="40px"
                    displayEmpty={true}
                    renderValue={(selected) => {
                      if(!selected){
                        return t('addBikeForm.placeholders.country')
                      }
                      return countriesOptions.find(({ value }) => value === selected).label
                    }}
                    options={countriesOptions}
                    renderOptions={({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )}
                    {...field}
                    ref={null}
                  />
                  <Text margin="5px 0 0 0" display="block" color="red">{errors?.country?.message}</Text>
                </FormControl>
              }
            />
        </Grid>
        <Grid item xs={6}>
          <Text margin="0 0 5px 0" display="block">{t('addBikeForm.labels.media')}</Text>
          <AddDocument height="40px" onSuccess={(res) => setValue('attachment_ids', [...getValues().attachment_ids, ...res.data.results.map(({ id }) => id)])}>
            <PlusIcon />
            <Text margin="0 0 0 5px" fontWeight="500" fontSize="12px" color="#7E8B99">
              {watch('attachment_ids').length ? ` ${watch('attachment_ids').length} ${watch('attachment_ids').length > 1 ? "files" : "file"} uploaded` : t('buttons.addMedia')}
            </Text>
          </AddDocument>
        </Grid>
        <Grid item marginLeft="auto" xs="auto">
          <Grid container spacing={1.5}>
            <Grid item xs="auto">
              <Button onClick={onCancel} variant="outlined">{t('buttons.cancel')}</Button>
            </Grid>
            <Grid item xs="auto">
              <Button type="submit" variant='contained'>{t('buttons.add')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  )
}

AddBikeForm.propTypes = {
}

AddBikeForm.defaultProps = {
}

export default AddBikeForm