import React from 'react'

const FileDownloadIcon = () => {
    return (
    <svg style={{ cursor: "pointer" }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 7C16.4142 7 16.75 7.33579 16.75 7.75V16.537L18.7413 14.6989C19.0457 14.4179 19.5202 14.4369 19.8011 14.7413C20.0821 15.0457 20.0631 15.5201 19.7587 15.8011L16.5087 18.8011C16.2214 19.0663 15.7786 19.0663 15.4913 18.8011L12.2413 15.8011C11.9369 15.5201 11.9179 15.0457 12.1989 14.7413C12.4799 14.4369 12.9543 14.4179 13.2587 14.6989L15.25 16.537V7.75C15.25 7.33579 15.5858 7 16 7ZM7.75 19C8.16421 19 8.5 19.3358 8.5 19.75V22.25C8.5 22.9404 9.05964 23.5 9.75 23.5H22.25C22.9404 23.5 23.5 22.9404 23.5 22.25V19.75C23.5 19.3358 23.8358 19 24.25 19C24.6642 19 25 19.3358 25 19.75V22.25C25 23.7688 23.7688 25 22.25 25H9.75C8.23122 25 7 23.7688 7 22.25V19.75C7 19.3358 7.33579 19 7.75 19Z" fill="#4A545E"/>
      <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#CFD6DD"/>
    </svg>
    )
}

export default FileDownloadIcon;