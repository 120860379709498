import React from 'react'

const LastPageIcon = () => {
    return (
			<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.1875 1.40625C9.1875 1.04381 8.89369 0.75 8.53125 0.75C8.16881 0.75 7.875 1.04381 7.875 1.40625L7.875 10.5938C7.875 10.9562 8.16881 11.25 8.53125 11.25C8.89369 11.25 9.1875 10.9562 9.1875 10.5938L9.1875 1.40625Z" fill="#4A545E"/>
        <path d="M1.10938 0.931549C0.847205 0.681295 0.431803 0.690956 0.181549 0.953126C-0.0687048 1.2153 -0.0590443 1.6307 0.203126 1.88095L4.51832 6L0.203126 10.119C-0.0590443 10.3693 -0.0687048 10.7847 0.181549 11.0469C0.431803 11.309 0.847205 11.3187 1.10938 11.0685L5.92188 6.4747C6.0516 6.35087 6.125 6.17934 6.125 6C6.125 5.82066 6.0516 5.64913 5.92188 5.5253L1.10938 0.931549Z" fill="#4A545E"/>
      </svg>

    )
}

export default LastPageIcon