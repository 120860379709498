import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { fetchRequestsCount } from '../../store/slices/requests/slice'
import { fetchMe } from '../../store/slices/users/slice'
import { Bagde } from '..';
import UserBlock from './user-block'
import { requestStatuses } from '../../core';
import { 
  BellIcon,
  DashboardIcon,
  FleetIcon,
  LogoIcon,
  RequestIcon
} from '../icons'
import { Aside, AsideHeader, MainUl, SubUl } from './styled'

const Sidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { requestsCount } = useSelector(state => state.requests)
  const { currentUser } = useSelector(state => state.users)
  const sidebarItems = [
    {
      title: t('dashboard.sidebarTitle'),
      icon: <DashboardIcon />,
      childrens : [],
      link: {
        pathname: '/dashboard',
        search: '',
      }
    },
    {
      title: t('fleet.sidebarTitle'),
      icon: <FleetIcon />,
      childrens : [
        {
          link: {
            pathname: '/fleet/map',
            search: '',
          },
          title: t('fleetMap.sidebarTitle'),
        },
        {
          link: {
            pathname: '/fleet/list',
            search: '',
          },
          title: t('fleetList.sidebarTitle'),
        }
      ]
    },
    {
      title: t('requests.sidebarTitle'),
      icon: <RequestIcon />,
      childrens : Object.values(requestStatuses).map(status => ({
        link: {
          pathname: '/requests',
          search: createSearchParams({ status }).toString(),
          searchParam: {
            value: status,
            name: 'status'
          },
          badge: true
        },
        title: t(`requests.statuses.${status}`)
      }))
    }
  ]

  useEffect(() => {
    dispatch(fetchMe())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(fetchRequestsCount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if(!requestsCount){
    return null
  }

  return (
    <Aside>
      <AsideHeader>
        <div>
          <LogoIcon/>
        </div>
        <BellIcon/>
      </AsideHeader>
      <MainUl>
        {sidebarItems.map(({ icon, title, childrens, link }) => 
            <li key={title} className={link ? 'link' : ''}>
              {
              link
                ? (
                  <NavLink to={{...link}} style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
                    {icon}
                    {title}
                  </NavLink>
                )
                : (
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    {icon}
                    {title}
                  </div>
                ) }
              {childrens?.length ? (
                <SubUl>
                  {childrens.map(({ link, title }) => 
                    <li key={`${link.pathname}${link.search}`}>
                      <NavLink
                        className={({ isActive }) => {
                          if(!link.searchParam?.value){
                            return isActive ? 'active' : ''
                          }
                          return isActive && searchParams.get(link.searchParam.name) === link.searchParam.value ? 'active' : ''
                        }}
                        to={{...link}}
                      >
                        {title}
                        {link.badge && <Bagde background="#fff" className="badge">{requestsCount[link.searchParam.value]}</Bagde>}
                      </NavLink>
                    </li>
                  )}
                </SubUl>
              ) : null}
            </li>
          )}
      </MainUl>
      <UserBlock currentUser={currentUser}/>
    </Aside>
  )
}

export default Sidebar