import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, MenuItem } from '@mui/material'
import { useParams, useNavigate, useLocation } from 'react-router'
import Dialog from '@mui/material/Dialog'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchRequest,
  fetchRequestHistory,
  setRequest
} from '../../store/slices/requests/slice'
import { fetchUsers } from '../../store/slices/users/slice'
import { fetchRequestsCount } from '../../store/slices/requests/slice'
import { 
  colorForFilters,
  axiosClient,
  normalizeAttachments
} from '../../core'
import { 
  BackArrowIcon,
  CopyIcon,
  Button,
  Select,
  StatusCircle,
  Text,
  Gallery,
  Video,
  ImageIcon,
  TextInput,
  VideoIcon
 } from '../../components'
import {
  requestStatuses,
  CircleColorsByStatus,
  getDateString,
  ChargeIconByCharge,
  copyToClipboard,
  getTTF,
  getChargeStatus,
  fileTypes
} from '../../core'
import {
  Header,
  PageWrapper,
  ContentWrapper,
  Preview
} from './styled'

const Request = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [ isVideosModalOpen, setIsVideosModalOpen] = useState(false)
  const [ isImagesModalOpen, setIsImagesModalOpen] = useState(false)
  const { id } = useParams()
  const { state } = useLocation()
  const { request, requestHistory } = useSelector(state => state.requests)
  const { users } = useSelector(state => state.users)
  const [localState, setLocalState] = useState(null)
  const defaultState = useMemo(() => ({ comment: request?.comment, assignee_id: request?.assignee?.id , status: request?.status }), [request])

  const renderVideo = (item) => <Video {...item} />

  const attachments = useMemo(() =>  normalizeAttachments(request?.attachments) || {}, [request])
  const assigneeOptions = useMemo(() => users?.map(({ id, first_name, last_name }) => ({ value: id, label: `${first_name} ${last_name}`})), [users])

  const discardHandler = () => setLocalState(defaultState)

  const handleSave = () => axiosClient.patch(`/service-requests/${id}`, { ...localState }).then(res => {
    dispatch(setRequest(res.data))
    dispatch(fetchRequestsCount())
  })

  const { ALL, ...statusesOptions } = requestStatuses
  
  useEffect(() => {
    dispatch(fetchRequest({ id }))
    dispatch(fetchUsers())
    dispatch(fetchRequestHistory({ id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if(request){
      setLocalState({ comment: request.comment, assignee_id: request.assignee?.id , status: request.status })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(setRequest(null)), [])
  if(!localState || !request){ 
    return null
  }
  return (
    <PageWrapper>
      <Header>
        <Grid container spacing={2} marginBottom={3} alignItems="center">
            <Grid item xs="auto" onClick={() => navigate(state?.backpath ? state.backpath : '/requests?status=ALL')}>
              <BackArrowIcon />
            </Grid>
            <Grid item xs="auto">
              <Text fontWeight="600" fontSize="18px" color="#272E35">
                {t('request.headerTitle')}&nbsp;
                {request?.uid}
              </Text>
            </Grid>
            <Grid item xs="auto" onClick={() => copyToClipboard(request?.uid)}>
              <CopyIcon />
            </Grid>
            {
              JSON.stringify(defaultState) !== JSON.stringify(localState) && (
              <Grid marginLeft="auto" item xs="auto">
                <Grid container spacing={1.5}>
                  <Grid item xs="auto">
                    <Button onClick={discardHandler} variant="outlined" size="small">{t('buttons.discard')}</Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button onClick={handleSave} variant='contained' size="small">{t('buttons.save')}</Button>
                  </Grid>
                </Grid>
              </Grid>
              )
            }
        </Grid>
        <Grid container rowSpacing={0} columnSpacing={4}>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.labels.ttf')}</Text>
            <Text fontSize="16px" fontWeight="500">{getTTF(request?.time_to_fix)}</Text>
          </Grid>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.labels.reporter')}</Text>
            <Text fontSize="16px" fontWeight="500">{`${request?.assignee_id ? `${request?.reporter?.first_name} ${request?.reporter?.last_name}` : t('other.unassigned')}`}</Text>
          </Grid>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.labels.status')}</Text>
              <Select
                outlined
                value={localState?.status}
                options={Object.values(statusesOptions)}
                renderOptions={(option) => (
                    <MenuItem key={option} value={option}>
                      <StatusCircle {...CircleColorsByStatus[option]}>
                        <Text fontSize="16px" fontWeight="500">{t(`requests.statuses.${option}`)}</Text>
                      </StatusCircle>
                    </MenuItem>
                )}
                onChange={(e) => setLocalState(prev => ({ ...prev, status: e.target.value }))}
              />
          </Grid>
          <Grid item xs="auto" display="flex" flexDirection="column">
            <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.labels.assignee')}</Text>
            <Select
              outlined
              value={localState.assignee_id}
              displayEmpty={true}
              renderValue={(selected) => {
                if(!localState.assignee_id){
                  return t('other.unassigned')
                }
                return assigneeOptions.find(({ value }) => value === selected)?.label
              }}
              options={assigneeOptions}
              renderOptions={({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <Text fontSize="16px" fontWeight="500">{label}</Text>
                </MenuItem>
              )}
              onChange={(e) => setLocalState(prev => ({ ...prev, assignee_id: e.target.value }))}
            />
          </Grid>
        </Grid>
      </Header>
      <ContentWrapper>
        <Grid container rowSpacing={0} columnSpacing={4}>
          <Grid item xs={9}>
            <Grid container rowSpacing={0} columnSpacing={7} marginBottom={4}>
              <Grid item xs="auto" display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.blocksTitles.unit')}</Text>
                <Text fontSize="16px" fontWeight="500">{request.unit.uid}</Text>
                {request.unit.rta_plate_number && (
                  <Text>{t('request.blocksTitles.plate')} {request.unit.rta_plate_number}</Text>
                )}
                <Grid container rowSpacing={0} columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <Text fontWeight="500">
                      <StatusCircle background={colorForFilters.status[request.unit.status]}>{t(`units.statuses.${request.unit.status}`)}</StatusCircle>
                    </Text>
                  </Grid>
                  <Grid item xs="auto">
                    {ChargeIconByCharge[getChargeStatus(request.unit.charge)]}
                    <Text color="#1D7C4D" fontSize="12px" fontWeight="500">&nbsp;{request.unit.charge ? `${parseInt(request.unit.charge)}%` : '—'}</Text>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto" display="flex" flexDirection="column">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.blocksTitles.client')}</Text>
                <Text fontSize="16px" fontWeight="500">{request.client.name}</Text>
              </Grid>
            </Grid>
            <Grid container spacing={0} flexDirection="column" marginBottom={2}>
              <Grid item xs="auto">
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.blocksTitles.issue')}</Text>
              </Grid>
              <Grid item xs="auto">
               <Text fontSize="16px" fontWeight="500">{t(`problemTypes.${request?.issue}`)}</Text>
              </Grid>
              <Grid item xs="auto">
                <Text fontSize="14px" fontWeight="400" color="#555F6D">{request?.description}</Text>
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={3} marginBottom={4}>
              {attachments[fileTypes.IMAGE] && (
                <Grid xs="auto" item display="flex" flexDirection="column">
                  <Preview onClick={() => setIsImagesModalOpen(true)} image={attachments[fileTypes.IMAGE][0]} />
                  <Grid container rowSpacing={0} columnSpacing={0.5} alignItems="center" marginTop={0.5}>
                    <Grid xs="auto" item display="flex" alignItems="center">
                      <ImageIcon />
                    </Grid>
                    <Grid xs="auto" item>
                        <Text fontSize="12px" fontWeight="500">{attachments[fileTypes.IMAGE].length}</Text>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {attachments[fileTypes.VIDEO] && (
                <Grid xs="auto" item display="flex" flexDirection="column">
                  <Preview onClick={() => setIsVideosModalOpen(true)} image="/images/video-preview.jpg" />
                  <Grid container rowSpacing={0} columnSpacing={0.5} alignItems="center" marginTop={0.5}>
                    <Grid xs="auto" item display="flex" alignItems="center">
                      <VideoIcon />
                    </Grid>
                    <Grid xs="auto" item>
                        <Text fontSize="12px" fontWeight="500">{attachments[fileTypes.VIDEO].length}</Text>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Text fontSize="10px" fontWeight="500" color="#555F6D" margin="0 0 5px 0">{t('request.blocksTitles.comment')}</Text>
            <TextInput width="100%" rows="10" textarea value={localState?.comment} onChange={e => setLocalState(prev => ({ ...prev, comment: e.target.value }))}/>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0} flexDirection="column">
              <Grid item xs={12}>
                <Text fontSize="10px" fontWeight="500" color="#555F6D">{t('request.blocksTitles.history')}</Text>
              </Grid>
              {requestHistory?.map((item) => {
                // TODO: add key
                const updatedBy = users.find(({ id }) => item.updated_by === id)
                return (
                  <Grid item xs={12} key={item.id}>
                    <Grid container rowSpacing={0} columnSpacing={1} marginBottom={1}>
                      <Grid item xs={6}>
                        <Text>{getDateString(new Date(item.updated))}</Text>
                      </Grid>
                      <Grid item xs={6} display="flex" flexDirection="column">
                        <Text fontWeight="500">
                          <StatusCircle {...CircleColorsByStatus[item.status]}>{t(`requests.statuses.${item.status}`)}</StatusCircle>
                        </Text>
                        <Text fontSize="12px" margin="0 0 0 14px">
                          by {`${updatedBy?.first_name} ${updatedBy?.last_name}`}
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                )  
              })}
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
      <Dialog open={isImagesModalOpen} onClose={() => setIsImagesModalOpen(false)} fullWidth maxWidth="lg">
        <Gallery items={attachments[fileTypes.IMAGE]?.map((url) => ({ original: url }))} showPlayButton={false} showThumbnails={false} />
      </Dialog>
      <Dialog open={isVideosModalOpen} onClose={() => setIsVideosModalOpen(false)} fullWidth maxWidth="lg">
        <Gallery
          items={attachments[fileTypes.VIDEO]?.map(url => ({
            poster: '/images/video-preview.jpg',
            videoUrl: url,
            renderItem: renderVideo
          }))}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
     />
      </Dialog>
    </PageWrapper>
  )
}


export default Request