import React from 'react'

 const LogoIcon = () => {
    return (
      <svg width="84" height="24" viewBox="0 0 84 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="83.7333" height="24" rx="12" fill="#393931"/>
        <path d="M7.46655 14.128H9.18922C9.33784 14.4928 9.53375 14.7968 9.77695 15.04C10.385 15.648 11.1686 15.952 12.1279 15.952C12.9926 15.952 13.6276 15.8102 14.033 15.5264C14.4518 15.2427 14.6612 14.8779 14.6612 14.432C14.6612 14.1888 14.5734 13.9794 14.3978 13.8038C14.2356 13.6281 13.9654 13.4727 13.5871 13.3376C13.2088 13.2025 12.871 13.1012 12.5738 13.0336C12.29 12.9525 11.8712 12.858 11.3172 12.7499C9.08789 12.2905 7.97322 11.2974 7.97322 9.77068C7.97322 8.97353 8.311 8.30473 8.98655 7.76428C9.66211 7.21033 10.6079 6.93335 11.8239 6.93335C13.3371 6.93335 14.5329 7.44002 15.4111 8.45335C15.7624 8.91273 16.0191 9.41939 16.1812 9.97335H14.4586C14.337 9.70313 14.1613 9.45317 13.9316 9.22348C13.4182 8.71006 12.7156 8.45335 11.8239 8.45335C11.1078 8.45335 10.5538 8.58846 10.162 8.85868C9.78371 9.12891 9.59455 9.43291 9.59455 9.77068C9.59455 10.0139 9.67562 10.2233 9.83775 10.399C10.0134 10.5746 10.2904 10.73 10.6687 10.8651C11.047 11.0002 11.378 11.1083 11.6618 11.1894C11.959 11.2569 12.3846 11.3447 12.9386 11.4528C15.1679 11.9122 16.2826 12.9053 16.2826 14.432C16.2826 15.2967 15.9178 16.0196 15.1882 16.6005C14.4586 17.1815 13.4385 17.472 12.1279 17.472C10.4795 17.472 9.20949 16.9181 8.31775 15.8101C7.93944 15.3237 7.65571 14.763 7.46655 14.128Z" fill="white"/>
        <path d="M17.0885 7.13602H18.8111L20.6351 15.7494H21.1418L22.8645 7.13602H25.8031L27.5258 15.7494H28.0325L29.8565 7.13602H31.5791L29.3498 17.2693H26.2085L24.4858 8.65602H24.1818L22.4591 17.2693H19.3178L17.0885 7.13602Z" fill="white"/>
        <path d="M33.8164 15.9723C32.8706 14.959 32.3977 13.7024 32.3977 12.2027C32.3977 10.703 32.8706 9.45317 33.8164 8.45335C34.7622 7.44002 35.9106 6.93335 37.2617 6.93335C38.1129 6.93335 38.829 7.09548 39.41 7.41975C40.0045 7.74402 40.4368 8.09531 40.707 8.47362L40.8894 8.77762H40.9097V7.13602H42.531V17.2693H40.9097V15.648H40.8894L40.707 15.9318C40.4638 16.2965 40.045 16.6478 39.4505 16.9856C38.856 17.3099 38.1264 17.472 37.2617 17.472C35.9106 17.472 34.7622 16.9721 33.8164 15.9723ZM34.9918 9.50722C34.3433 10.2098 34.019 11.1083 34.019 12.2027C34.019 13.2971 34.3433 14.1956 34.9918 14.8981C35.6539 15.6007 36.4781 15.952 37.4644 15.952C38.4507 15.952 39.2681 15.6007 39.9166 14.8981C40.5787 14.1956 40.9097 13.2971 40.9097 12.2027C40.9097 11.1083 40.5787 10.2098 39.9166 9.50722C39.2681 8.80464 38.4507 8.45335 37.4644 8.45335C36.4781 8.45335 35.6539 8.80464 34.9918 9.50722Z" fill="white"/>
        <path d="M44.7675 21.12V7.13602H46.3888V8.75735H46.4091L46.6118 8.45335C46.8415 8.10206 47.2535 7.76428 47.848 7.44002C48.4425 7.10224 49.1721 6.93335 50.0368 6.93335C51.3879 6.93335 52.5364 7.44002 53.4822 8.45335C54.4279 9.45317 54.9008 10.703 54.9008 12.2027C54.9008 13.7024 54.4279 14.959 53.4822 15.9723C52.5364 16.9721 51.3879 17.472 50.0368 17.472C49.1721 17.472 48.4358 17.3099 47.8278 16.9856C47.2333 16.6478 46.8279 16.2965 46.6118 15.9318L46.4091 15.648H46.3888V21.12H44.7675ZM47.3616 9.50722C46.7131 10.2098 46.3888 11.1083 46.3888 12.2027C46.3888 13.2971 46.7131 14.1956 47.3616 14.8981C48.0237 15.6007 48.8479 15.952 49.8342 15.952C50.8205 15.952 51.6379 15.6007 52.2864 14.8981C52.9485 14.1956 53.2795 13.2971 53.2795 12.2027C53.2795 11.1083 52.9485 10.2098 52.2864 9.50722C51.6379 8.80464 50.8205 8.45335 49.8342 8.45335C48.8479 8.45335 48.0237 8.80464 47.3616 9.50722Z" fill="white"/>
        <path d="M56.5293 21.12V7.13602H58.1506V8.75735H58.1709L58.3736 8.45335C58.6032 8.10206 59.0153 7.76428 59.6098 7.44002C60.2043 7.10224 60.9339 6.93335 61.7986 6.93335C63.1497 6.93335 64.2982 7.44002 65.244 8.45335C66.1897 9.45317 66.6626 10.703 66.6626 12.2027C66.6626 13.7024 66.1897 14.959 65.244 15.9723C64.2982 16.9721 63.1497 17.472 61.7986 17.472C60.9339 17.472 60.1976 17.3099 59.5896 16.9856C58.9951 16.6478 58.5897 16.2965 58.3736 15.9318L58.1709 15.648H58.1506V21.12H56.5293ZM59.1234 9.50722C58.4749 10.2098 58.1506 11.1083 58.1506 12.2027C58.1506 13.2971 58.4749 14.1956 59.1234 14.8981C59.7855 15.6007 60.6096 15.952 61.596 15.952C62.5823 15.952 63.3997 15.6007 64.0482 14.8981C64.7103 14.1956 65.0413 13.2971 65.0413 12.2027C65.0413 11.1083 64.7103 10.2098 64.0482 9.50722C63.3997 8.80464 62.5823 8.45335 61.596 8.45335C60.6096 8.45335 59.7855 8.80464 59.1234 9.50722Z" fill="white"/>
        <path d="M67.3791 17.2693V15.7494L73.4591 8.67628V8.65602H67.5817V7.13602H75.5871V8.65602L69.5071 15.7291V15.7494H75.7897V17.2693H67.3791Z" fill="white"/>
      </svg>
    )
}

export default LogoIcon