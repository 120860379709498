import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchBatteries = createAsyncThunk(
  'fetchBatteries',
  async ({ filters }) => {
    const response = await axiosClient.get('/batteries/', {
      params : {
        ...filters
      }
    });
    return response.data.results;
  }
)

const initialState = {
  batteries: [],
}

export const slice = createSlice({
  name: 'batteries',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchBatteries.fulfilled, (state, { payload }) => {
      state.batteries = payload
    })
  }
})

export {
  fetchBatteries,
}

export default slice.reducer;
