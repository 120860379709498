import { styled } from '@mui/material/styles'

export const DropdownIconWrapper = styled('div')`
  transform: rotate(${props => props.isOpen ? '180deg' : 0});
  display: flex;
  margin-left: 10px;
`

export const CustomRangeWrapper = styled('div')`
  padding: 16px;
  border-left: 1px solid #E9ECEF;
`