import React from 'react'

function MetricArrowUpIcon({ color, ...rest }) {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.48598 0.20385C3.77476 -0.06795 4.22524 -0.06795 4.51402 0.20385L7.76402 3.20385C8.06565 3.48774 8.08004 3.96239 7.79615 4.26402C7.51226 4.56565 7.03761 4.58004 6.73598 4.29615L4.75 2.48582L4.75 9.25C4.75 9.66421 4.41422 10 4 10C3.58579 10 3.25 9.66421 3.25 9.25L3.25 2.48582L1.26403 4.29615C0.962395 4.58004 0.48774 4.56565 0.203852 4.26402C-0.0800351 3.96239 -0.0656517 3.48774 0.235978 3.20385L3.48598 0.20385Z" fill={color}/>
    </svg>

  )
}

export default MetricArrowUpIcon
