import i18n from '../../18n'
import { format } from 'date-fns'

export const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toDateString() === date.toDateString()
}

export const isToday = (date) => {
  const today = new Date();
  return today.toDateString() === date.toDateString()
}

export const isCurrentYear = (date) => date.getUTCFullYear() === new Date().getUTCFullYear()

export const getDateString = (date) => {
  if(isToday(date)){
    return `${i18n.t('date.today')} ${format(date, 'HH:mm')}`
  }
  if(isYesterday(date)){
    return `${i18n.t('date.yesterday')} ${format(date, 'HH:mm')}`
  }
  
  if(isCurrentYear(date)){
    return format(date, 'd MMM HH:mm')
  }

  return format(new Date(date), 'd MMM yyyy HH:mm')
}

export const getTTF = (ttf) => {
  if(!ttf){
    return <span dangerouslySetInnerHTML={{__html: '&mdash;'}} />
  }

  const timeDiff = new Date().getTime() - new Date(ttf).getTime()

  if(timeDiff >= 86400000){
    return 'OD'
  }

  return `${24 - Math.round(timeDiff / 1000 / 60 / 60)}h`
}