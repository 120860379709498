import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  Grid,
  MenuItem,
  TableSortLabel 
} from '@mui/material'

import {
   Select,
   Text,
   PrevPageIcon,
   NextPageIcon,
   FirstPageIcon,
   LastPageIcon
} from '../../components'
import { 
  StyledTableCell,
  TableScrollWrapper,
  StyledTableHead,
  CellInner,
  NextButton,
  PrevButton
} from './styled'

const CommonTable = ({ columns, data, onRowClick, pagination = {}, onHeadCellClick, order, direction }) => {
  const { 
    paginationSelector,
    pagintaionSetter 
  } = pagination
  const showByOptions = [10,20,50,100]
  const getSelector = () => {
    if(paginationSelector){
      return paginationSelector
    }
    return(state) => state
  }
  const { count = 1, page = 1, page_size = 10 } = useSelector(getSelector())
  const pageOptions = useMemo(() => {
    const options = []
    for(let i = 0; i < Math.ceil(count / page_size); i++){
      options.push(i + 1)
    }
    return options
  }, [count, page_size])

  const endValue = useMemo(
    () => page_size * (page - 1) + page_size,
    [page, page_size],
  )
  return (
    <>
      {
        Object.keys(pagination).length !== 0 && (
          <Grid container rowSpacing={0} columnSpacing={2} marginBottom={2}>
            <Grid item xs="auto">
              <PrevButton disabled={page === 1} onClick={() => pagintaionSetter({ page: page - 1})}><PrevPageIcon /></PrevButton>
              <NextButton disabled={page === pageOptions.length} onClick={() => pagintaionSetter({ page: page + 1})}><NextPageIcon /></NextButton>
            </Grid>
            <Grid item xs="auto">
              <Select
                value={page}
                paddingTop="6px"
                paddingBottom="6px"
                height="34px"
                onChange={(e) => pagintaionSetter({ page: e.target.value })}
                displayEmpty={true}
                renderValue={(selected) => <Text fontSize="12px" fontWeight="500" color="#555F6D">{`Page ${selected} of ${Math.ceil(count / page_size)}`}</Text>}
                options={pageOptions}
                renderOptions={(value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                )}
                ref={null}
              />
            </Grid>
            <Grid item xs="auto">
              <PrevButton disabled={page === 1} onClick={() => pagintaionSetter({ page: 1 })}><FirstPageIcon /></PrevButton>
              <NextButton disabled={page === pageOptions.length} onClick={() => pagintaionSetter({ page: pageOptions.length })}><LastPageIcon /></NextButton>
            </Grid>
            <Grid item xs="auto" marginLeft="auto" display="flex" alignItems="center">
              <Text fontSize="12px" fontWeight="500" color="#555F6D">{`${page_size * (page - 1) + 1}–${endValue < count ? endValue : count} of ${count} items`}</Text>
            </Grid>
            <Grid item xs="auto">
              <Select
                  value={page_size}
                  paddingTop="6px"
                  paddingBottom="6px"
                  height="34px"
                  onChange={(e) => pagintaionSetter({ page_size: e.target.value, page: 1 })}
                  displayEmpty={true}
                  renderValue={(selected) => <Text fontSize="12px" fontWeight="500" color="#555F6D">{`Show by ${selected}`}</Text>}
                  options={showByOptions}
                  renderOptions={(value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  )}
                  ref={null}
                />
            </Grid>
          </Grid>
        )
      }
      <TableScrollWrapper>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              {columns.map(({ headCell, field }) => (
                <TableCell key={headCell}>
                  <TableSortLabel
                    disabled={!onHeadCellClick}
                    onClick={() => onHeadCellClick(field)}
                    active={onHeadCellClick && field === order}
                    direction={direction}
                  >
                    {headCell}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {data.map((row, index) => 
              <TableRow style={{ cursor: onRowClick ? 'pointer': 'initial', background: row.rowBg || 'initial' }} key={`row.id${index}`} onClick={() => onRowClick(row)}>
                  {columns.map((column) => 
                    <StyledTableCell key={column.headCell}>
                      <CellInner>
                        {column.getValue(row)}
                      </CellInner>
                    </StyledTableCell>
                  )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableScrollWrapper>
    </>
  )
}

export default CommonTable;