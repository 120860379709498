import { styled } from '@mui/material/styles'

export const StyledStatusCircle = styled('div')`
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: ${props => props.background ? "inline-block" : 'none'};
    margin-right: 6px;
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid ${props => props.borderColor || props.background};
    background: ${props => props.background};
  }
`