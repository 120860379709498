import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'

const fetchClients = createAsyncThunk(
  'fetchClients',
  async () => {
    const response = await axiosClient.get('/clients/')
    return response.data.results
  }
)

const initialState = {
  clients: [],
}

export const slice = createSlice({
  name: 'clients',

  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, { payload }) => {
      state.clients = payload
    })
  }
})

export {
  fetchClients,
}

export default slice.reducer;
