import React, { useRef, useState, useEffect } from 'react'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import  { LngLatBounds } from 'mapbox-gl'
import maplibregl from 'maplibre-gl';
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from 'react-use-websocket';

import {
  fetchMapUnits,
  fetchMapDrawerUnits,
  fetchMapUnitsCounts,
  updateMapsUnits
} from '../../store/slices/fleet-map/slice'
import { FleetHeader, MapMarkerIcon } from '../../components'
import { getSoketsUrl } from '../../core'
import { colorForFilters, fieldByFilterBy } from '../../core'
import Drawner from './drawner'
import DrawnerUnit from './drawner-unit'
import MapZoom from './map-zoom'
import MapStylesSwitcher from './map-styles-switcher'
import { PageWrapper, MapWrapper, MapTools } from './styled'

const FleetMap = () => {
  const dispatch = useDispatch()
  const { lastMessage } = useWebSocket(`${getSoketsUrl()}/units/map/`, {
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true
    },
  })
  useEffect(() => {
    if (lastMessage !== null) {
      console.log('updated')
      dispatch(updateMapsUnits(JSON.parse(lastMessage.data).bikes))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const { 
    fleetMap: {
      filterBy,
      selectedFilters,
      mapItems
    },
   } = useSelector(state => state);

  const [mapClickLocation, setMapClickLocation] = useState();

  const [viewport, setViewport] = useState({
    latitude:  25.276987,
    longitude: 55.296249,
    zoom: 10,
    height: "100%",
    width: "100%"
  });

  const [popup, setPopup] = useState({
    lat: 0,
    lon: 0,
    content: undefined
  })

  const mapRef = useRef(null);

  const closePopupHandler = () => setPopup({
    lat: 0,
    lon: 0,
    content: undefined
  })

  const onMarkerClickHandler = (unit) => setPopup({
    lat: unit.location.lat,
    lon: unit.location.lon,
    content: unit
  })

  const zoomIn = () => {
    if (viewport.zoom && viewport.zoom <= 20) {
      const map = mapRef.current?.getMap()
      map.flyTo({ zoom: viewport.zoom + 1 })
    }
  }

  const zoomOut = () => {
    if (viewport.zoom && viewport.zoom >= 2) {
      const map = mapRef.current?.getMap()
      map.flyTo({ zoom: viewport.zoom - 1 })
    }
  }

  const fitMapByBounds = (coordinates, duration = 500) => {
    const map = mapRef.current?.getMap()
    const bounds = new LngLatBounds()
    coordinates.forEach(coordinate => bounds.extend(coordinate))
    map.fitBounds(Object.values(bounds).map(item => Object.values(item)), { duration })
    setTimeout(() => setViewport(prev => {
      const map = mapRef.current?.getMap()
      map.flyTo({ zoom: prev.zoom-=0.1 })
      return prev
    }), 600)
  }

  const flyToMarker = ({ lat, lon, zoom }) => {
    const map = mapRef.current?.getMap()
    map.flyTo({ center: [lon, lat], zoom })
  }

  const setMapStyle = (src) => {
    const map = mapRef.current?.getMap()
    map.setStyle(src)
  }

  useEffect(() => {
    closePopupHandler();
  }, [filterBy, selectedFilters])

  useEffect(() => {
    dispatch(fetchMapDrawerUnits({ filters:{
      [filterBy]: selectedFilters.join(',')
    }}))
    dispatch(fetchMapUnits({ filters:{
      [filterBy]: selectedFilters.join(',')
    }}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, filterBy])

  useEffect(() => {
    dispatch(fetchMapUnitsCounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy])
  return (
    <PageWrapper>
      <FleetHeader/>
      <MapWrapper>
        <MapTools>
          <MapZoom zoomIn={zoomIn} zoomOut={zoomOut} />
          <MapStylesSwitcher setStyle={setMapStyle} />
        </MapTools>
        <Drawner flyToMarker={flyToMarker} />
        {mapItems.length ? 
          <ReactMapGL
            mapLib={maplibregl}
            {...viewport}
            onLoad={() => fitMapByBounds(mapItems.map(({ location }) => Object.values(location).reverse()))}
            onMouseUp={(e) => {
              if(mapClickLocation.x === e.point.x && mapClickLocation.y === e.point.y){
                closePopupHandler()
              }
            }}
            onMouseDown={e => setMapClickLocation({ x: e.point.x , y: e.point.y })}
            ref={mapRef}
            clickRadius={25}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle="https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM"
            onMove={(e) => {
              setViewport(e.viewState)
            }}
          >
            {popup.content && (
              <Popup
                maxWidth="auto"
                latitude={popup.content.location.lat}
                longitude={popup.content.location.lon}
                closeButton={false}
                closeOnClick={false}
                onClose={closePopupHandler}
              >
                  <DrawnerUnit
                    key={popup.content.id}
                    uid={popup.content.uid}
                    charge={popup.content.charge}
                    status={popup.content.status}
                    subscriptionType={popup.content.subscription_type}
                    rtaPlateNumber={popup.content.rta_plate_number}
                    modelName={popup.content.bike_model.name}
                    isLast
                  />
              </Popup>
            )}
            {mapItems.map((bike) => (
              <Marker
                onClick={() => onMarkerClickHandler(bike)}
                key={bike.id}
                latitude={bike?.location?.lat}
                longitude={bike?.location?.lon}
              >
                <MapMarkerIcon
                  color={colorForFilters[filterBy][bike[fieldByFilterBy[filterBy]]]}
                />
              </Marker>
            ))}
          </ReactMapGL>
          : null
        }
      </MapWrapper>
    </PageWrapper>
  )
}

export default FleetMap