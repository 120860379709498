import React from 'react'

const CheckIcon = () => {
    return (
			<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7724 0.211815C14.0696 0.500305 14.0767 0.975126 13.7882 1.27236L5.53819 9.77236C5.39571 9.91915 5.19945 10.0014 4.99489 9.99998C4.79032 9.99859 4.5952 9.91369 4.45474 9.76497L0.204742 5.26497C-0.0796667 4.96383 -0.0661045 4.48915 0.235034 4.20474C0.536173 3.92033 1.01085 3.93389 1.29526 4.23503L5.0074 8.16553L12.7118 0.227644C13.0003 -0.0695877 13.4751 -0.0766745 13.7724 0.211815Z" fill="#272E35"/>
      </svg>


    )
}

export default CheckIcon;