import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosClient } from '../../../core/axios-client'
import { FleetFilters } from '../../../core/constants'

const fetchMapUnits = createAsyncThunk(
  'fetchMapUnits',
  async ({ filters }) => {

    const units = await axiosClient.get('/bikes/', {
      params: {
        ...filters,
      }
    });

    return units.data.results
  }
)

const fetchMapDrawerUnits = createAsyncThunk(
  'fetchMapDrawerUnits',
  async ({ filters }) => {

    const units = await axiosClient.get('/bikes/', {
      params: {
        ...filters,
      }
    })
    
    return units.data.results
  }
)

const fetchMapUnitsCounts = createAsyncThunk(
  'fetchUnitsCounts',
  async () => {

    const statusCount = await axiosClient.get('/bikes/count/status/');

    const subscriptionCount = await axiosClient.get('/bikes/count/subscription/');

    return {
      status: statusCount.data,
      subscription_type: subscriptionCount.data,
  }
})

const initialState = {
  filterBy: 'subscription_type',
  filters: FleetFilters['subscription_type'],
  selectedFilters: [],
  drawnerOpen: true,
  drawerItems: [],
  mapItems: [],
  counts: {
    status: null,
    subscription_type: null
  },
}

export const slice = createSlice({
  name: 'fleet-map',

  initialState,

  reducers: {
    setMapFilterBy(state, { payload }) {
      state.filterBy = payload
      state.selectedFilters = []
      state.filters = FleetFilters[payload]
    },
    setMapSelectedFilters(state, { payload }){
      if(state.selectedFilters.includes(payload)){
        state.selectedFilters = state.selectedFilters.filter(filter => filter !== payload)
      } else {
        state.selectedFilters.push(payload)
      } 
    },
    setDrawner(state, { payload }){
      state.drawnerOpen = payload
    },
    resetMapSelectedFilters(state){
      state.selectedFilters = []
    },
    updateMapsUnits(state, { payload }){
      state.mapItems = state.mapItems.map(item => {
        let proxyItem = {...item}
        const soketItem = payload.find(payloadItem => payloadItem.id === item.id)
        if(soketItem){
          proxyItem = {
            ...proxyItem,
            ...soketItem
          }
        }
        return proxyItem
      })
      
      state.drawerItems = state.drawerItems.map(item => {
        let proxyItem = {...item}
        const soketItem = payload.find(payloadItem => payloadItem.id === item.id)
        if(soketItem){
          proxyItem = {
            ...proxyItem,
            ...soketItem
          }
        }
        return proxyItem
      })
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMapUnits.fulfilled, (state, { payload }) => {
      state.mapItems = payload.filter(bike => bike?.location?.lat && bike?.location?.lon);
    })

    builder.addCase(fetchMapDrawerUnits.fulfilled, (state, { payload }) => {
      state.drawerItems = payload;
    })

    builder.addCase(fetchMapUnitsCounts.fulfilled, (state, { payload }) => {
      state.counts.status = payload.status;
      state.counts.subscription_type = payload.subscription_type;
    })
  }
})

const {
  setMapFilterBy,
  setDrawner,
  resetMapSelectedFilters,
  setMapSelectedFilters,
  updateMapsUnits
} = slice.actions

export {
  setMapFilterBy,
  setDrawner,
  resetMapSelectedFilters,
  setMapSelectedFilters,
  fetchMapUnits,
  fetchMapDrawerUnits,
  fetchMapUnitsCounts,
  updateMapsUnits
}

export default slice.reducer
