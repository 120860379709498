const FLAT_TIRE = 'FLAT_TIRE'
const LOOSE_BRAKES = 'LOOSE_BRAKES'
const WORN_TIRE = 'WORN_TIRE'
const BATTERY = 'BATTERY'
const CHARGER = 'CHARGER'
const OTHER = 'OTHER'

export const problemTypes = {
  FLAT_TIRE,
  LOOSE_BRAKES,
  WORN_TIRE,
  BATTERY,
  CHARGER,
  OTHER 
}