import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import  { LngLatBounds } from 'mapbox-gl'
import { useLocation } from 'react-router-dom'
import maplibregl from 'maplibre-gl'
import ReactMapGL, { Marker } from 'react-map-gl'
import CloseIcon from '@mui/icons-material/Close'
import { createBrowserHistory } from "history"
import { format } from 'date-fns'
import {
  Autocomplete,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip
} from '@mui/material'

import { fetchClients } from '../../store/slices/clients/slice'
import { 
  fetchDashboardBikes,
  fetchMetrics,
  fetchPastMetrics,
  fetchSubscriptionCount,
  fetchClientCount
} from '../../store/slices/dashboard/slice'
import { 
   HeatBikesLayer,
   SubscriptionFilters,
   prepQuery,
   prepPastMetricsDates,
   getMetricsDiff
} from '../../core'
import { 
  Text,
  CheckIcon,
  AutocompletePlansIcon,
  AutocompleteClientsIcon,
  DropdownIcon,
  ResetIcon,
  InfoIcon,
  MapMarkerIcon,
  LoaderIcon,
  DatePopup
} from '../../components'
import {
  PageWrapper,
  Header,
  AutocompleteIcon,
  SearchWithIcon,
  ChipItem,
  LoaderWrapper
} from './styled'

import MapBikeModal from './map-bike-modal'

// dashboard heatmap fix 2
import { axiosClient } from '../../core'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { state } = useLocation()
  const history = createBrowserHistory()
  const mapRef = useRef(null)
  const { clients } = useSelector(state => state.clients)
  const { currentUser } = useSelector(state => state.users)
  const { 
    dashboardBikes, 
    metrics,
    pastMetrics,
    clientCount,
    subscriptionCount
  } = useSelector(state => state.dashboard)
  const [viewport, setViewport] = useState({
    zoom: 1,
    height: "100%",
    width: "100%"
  })
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [modalBike, setModalBike] = useState({})
  const [isHeatMap, setisHeatMap] = useState(true)
  const defaultDateValue = {
    label: t("date.today"),
    value: {
      date_from: format(new Date(), 'yyyy-MM-dd'),
      date_to: format(new Date(), 'yyyy-MM-dd'),
    }
  }
  const [dateForFilter, setDateForFilter] = useState(state?.dateFilter || defaultDateValue)
  const [filterDate, setFilterDate] = useState(0)
  const [autocompliteClients, setAutocompliteClients] = React.useState(state?.cliensFilter || [])
  const [autocomplitePlans, setAutocomplitePlans] = React.useState(state?.plansFilter || [])
  const resetFilters = () => {
    setFilterDate(0)
    setDateForFilter(defaultDateValue)
    setAutocompliteClients([])
    setAutocomplitePlans([])
  }
  const plansOptions = useMemo(() => SubscriptionFilters.map(plan => ({ 
    label: t(`units.subscriptions.${plan}`),
    value: plan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [])
  const clientsOptions = useMemo(() => clients?.map(client => ({ 
    label: client.name,
    value: client.id
  })), [clients])

  const sortItems = ({ value }, selected) => {
    const a = selected?.find((item) => item.value === value);
    if (a) return -1;
    if (!a) return 1;
    return 0;
  }
  const fleets = [
    {
      field: 'mileage',
      size: 12,
      measure: 'km',
      isReverse: false,
      normalize: (value) => Math.round(value / 1000)
    },
    {
      field: 'utilization',
      size: 12,
      measure: '%',
      isReverse: false,
      normalize: (value) => value
    },
    {
      field: 'downtime',
      size: 12,
      measure: 'h',
      isReverse: true,
      normalize: (value) => Math.round(value / 3600)
    },
    {
      field: 'breakdowns',
      size: 12,
      measure: '',
      isReverse: true,
      normalize: (value) => value
    },
  ]

  const query = useMemo(() => prepQuery({
    date_from: dateForFilter.value.date_from,
    date_to: dateForFilter.value.date_to,
    subscription_type: autocomplitePlans.map((({ value }) => value)).join(','),
    client_id: autocompliteClients.map((({ value }) => value)).join(','),
  }), [dateForFilter, autocomplitePlans, autocompliteClients])

  useEffect(() => {
    const map = mapRef.current?.getMap()
    if(isHeatMap && map){ 
      const queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join('&')
      map.getSource('heatBikeSource').setData(`https://app.swappz.co/api/v1/dashboard/heatmap/?${queryString}&user_id=${currentUser?.id}`)
      map.setLayoutProperty('heatBikeSource-id', 'visibility', 'visible')
    } else if(map) {
      map.setLayoutProperty('heatBikeSource-id', 'visibility', 'none')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeatMap, dateForFilter, autocompliteClients, autocomplitePlans])

  useEffect(() => {
    dispatch(fetchDashboardBikes({ params: query }))
    dispatch(fetchMetrics({ params: query }))
    dispatch(fetchPastMetrics({ 
      params: prepQuery({
        ...prepPastMetricsDates({
          date_from: dateForFilter.value.date_from,
          date_to: dateForFilter.value.date_to,
          isMounth: filterDate === 4 || filterDate === 5,
          isYear: filterDate === 6 || filterDate === 7
        }),
        subscription_type: autocomplitePlans.map((({ value }) => value)).join(','),
        client_id: autocompliteClients.map((({ value }) => value)).join(','),
      })
     }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateForFilter, autocompliteClients, autocomplitePlans])
  useEffect(() => {
    const map = mapRef.current?.getMap()
    const bikesWithLocation = dashboardBikes.filter(({ location }) => Boolean(location))
    if(bikesWithLocation.length && map){
      console.log(11)
      const bounds = new LngLatBounds()
      const coordinates = bikesWithLocation.map(({ location }) => Object.values(location).reverse())
      coordinates.forEach(coordinate => bounds.extend(coordinate))
      map.fitBounds(Object.values(bounds).map(item => Object.values(item)), { duration: 500 })
      setTimeout(() => setViewport(prev => {
        const map = mapRef.current?.getMap()
        map.flyTo({ zoom: prev.zoom-=0.1 })
        return prev
      }), 600)
    }
  }, [dashboardBikes])
  useEffect(() => {
    dispatch(fetchClients())
    dispatch(fetchClientCount())
    dispatch(fetchSubscriptionCount())
    history.replace({ ...history.location, state: {}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PageWrapper>
      <Dialog open={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} maxWidth="sm" sx={{ padding: 16 }}>
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid xs="auto" item>
              <Text fontWeight="500" fontSize="18px">How the stats are calculated</Text>
            </Grid>
            <Grid xs="auto" item>
              <IconButton onClick={() => setIsInfoModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Text color="#555F6D" margin="20px 0 0 0">
            Changes in indicators are measured as a percentage and show how the value of the indicator has changed compared to the previous period. They are calculated according to the time period you choose.
          </Text>
          <Text color="#555F6D" margin="20px 0">
            If you have selected an arbitrary time period, for example two days, then the comparison is made with the values of the days preceding the selected ones. In our example it is two days preceding the selected ones.
          </Text>
          <Text color="#555F6D">
            If you have selected a preset time period, then the comparison is made with the entire similar period in the past. For example, if you select the period "This week", then the comparison will be made with the values of the entire previous week, even if the current week has not yet ended.
          </Text>
        </DialogContent>
      </Dialog>
      <Header>
        <Grid height="22.4px" container justifyContent="space-between" alignItems="center" rowSpacing={0} columnSpacing={2} marginBottom={3}>
          <Grid item xs="auto">
            <Text fontWeight="600" fontSize="18px">
              {t('dashboard.headerTitle')}
            </Text>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={() => setIsInfoModalOpen(true)}>
              <InfoIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs="auto">
            <DatePopup initialDate={dateForFilter} setDate={setDateForFilter} />
          </Grid>
          <Grid item xs="auto">
            <Autocomplete
              multiple
              size="small"
              sx={{
                width: 230,
                 position: 'relative',
                '& .MuiInputBase-root': {
                  paddingLeft: '15px!important'
                }
              }}
              className='withoutShrinkLabel withIcon'
              isOptionEqualToValue={(option, value) => option.label === value.label}
              value={autocomplitePlans}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                setAutocomplitePlans([...newValue])
              }}
              renderOption={(props, option) => {
                const isSelected = props['aria-selected']
                return  <li {...props} style={{ paddingLeft: isSelected ? 16 : 40 }}>
                          {isSelected && <><CheckIcon />&nbsp;&nbsp;</>}
                          <Tooltip title={option.label}>
                            <Text display="inline-block" margin="0 15px 0 0" ellipsis>{option.label}</Text>
                          </Tooltip>
                          <Text display="inline-block" margin="0 0 0 auto">{subscriptionCount[option.value]}</Text>
                        </li>
              }}
              popupIcon={<DropdownIcon />}
              renderTags={(tagValue) => <Text ellipsis fontSize="14px" fontWeight="600" nowrap margin="0 0 0 20px">{`${tagValue.length} ${tagValue.length > 1 ? 'plans' : 'plan'}`}</Text>}
              options={plansOptions.sort(a => sortItems(a, autocomplitePlans))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <>
                  <AutocompleteIcon><AutocompletePlansIcon /></AutocompleteIcon>
                  <SearchWithIcon nowrap mL={autocomplitePlans.length ? 0 : '20px'} style={{ marginLeft: 0}} {...params} label="All plans" placeholder="search" />
                </>
              )}
            />
          </Grid>
          <Grid item xs="auto">
            <Autocomplete
              multiple
              size="small"
              sx={{
                width: 230,
                 position: 'relative',
                '& .MuiInputBase-root': {
                  paddingLeft: '15px!important'
                }
              }}
              className='withoutShrinkLabel withIcon'
              isOptionEqualToValue={(option, value) => option.label === value.label}
              value={autocompliteClients}
              onChange={(event, newValue) => {
                setAutocompliteClients([...newValue])
              }}
              disableListWrap
              renderOption={(props, option) => {
                const isSelected = props['aria-selected']
                return  <li {...props} style={{ paddingLeft: isSelected ? 16 : 40 }}>
                          {isSelected && <><CheckIcon />&nbsp;&nbsp;</>}
                          <Text display="inline-block" margin="0 15px 0 0" ellipsis>{option.label}</Text>
                          <Text display="inline-block" margin="0 0 0 auto">{clientCount[option.value] || 0}</Text>
                        </li>
              }}
              disableCloseOnSelect
              popupIcon={<DropdownIcon />}
              renderTags={(tagValue) => <Text ellipsis fontSize="14px" fontWeight="600" nowrap margin="0 0 0 20px">{`${tagValue.length} ${tagValue.length > 1 ? 'clients' : 'client'}`}</Text>}
              options={clientsOptions.sort(a => sortItems(a, autocompliteClients))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <>
                  <AutocompleteIcon bottom="9px">
                    <AutocompleteClientsIcon />
                  </AutocompleteIcon>
                  <SearchWithIcon nowrap mL={autocompliteClients.length ? 0 : '20px'} style={{ marginLeft: 0}} {...params} label="All clients" placeholder="search" />
                </>
              )}
            />
          </Grid>
          {
            (autocompliteClients?.length || autocomplitePlans?.length || filterDate !== 0) && (
              <Grid xs="auto" item display="flex" alignItems="center" style={{ cursor: "pointer" }} onClick={resetFilters}>
                <ResetIcon />
                <Text margin="0 0 0 10px" fontWeight="500" color="#4A545E">Reset</Text>
              </Grid>
            )
          }
          <Grid item xs="auto" marginLeft="auto">
          <FormControlLabel
            control={
              <Switch
                checked={isHeatMap}
                onChange={e => setisHeatMap(e.target.checked)}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontWeight: 500,
                fontFamily: 'Inter',
                fontSize: 14
              }
            }}
            label="Heatmap"
          />
          </Grid>
        </Grid>
      </Header>
      <Grid container spacing={0} flexGrow={1}>
        <Grid position="relative" item xs={3} borderRight="1px solid #E9ECEF" sx={{ background: '#FBFCFD' }}>
          {
            (!Object.keys(metrics).length
            || !Object.keys(pastMetrics).length)
            && (
              <LoaderWrapper>
                <LoaderIcon />
              </LoaderWrapper>
            )
          }
          {metrics?.fleet && (
            <Grid container rowSpacing={0} columnSpacing={1} padding="20px 16px 10px">
              <Grid  item xs="auto" marginBottom={1}>
                <ChipItem background="#F0F3F5">
                  <Text fontWeight="500" fontSize="12px">All plans</Text>
                  <Text fontWeight="500" fontSize="18px">{metrics?.fleet['by-subscription'].reduce((acc, { count }) => acc += count, 0)}</Text>
                </ChipItem>
              </Grid>
              {
                metrics?.fleet['by-subscription'].map(({ subscription, count }) => (
                  <Grid key={subscription} item xs="auto" marginBottom={1}>
                    <ChipItem>
                      <Text fontWeight="500" fontSize="12px">{t(`units.subscriptions.${subscription}`)}</Text>
                      <Text fontWeight="500" fontSize="18px">{count}</Text>
                    </ChipItem>
                  </Grid>
                ))
              }
            </Grid>
          )}
          <Grid container rowSpacing={0} columnSpacing={2} padding="10px 16px" marginBottom={3}>
              {
                fleets.map(({ field, size, measure, isReverse, normalize }) => (
                  <Grid key={field} item xs={size} marginBottom={2}>
                    <ChipItem>
                      <Text fontWeight="500" margin="0 0 8px 0">{t(`metrics.${field}`)}</Text>
                      <div>
                        <Text style={{ alignItems: 'center' }} fontWeight="500" color="#555F6D">
                          {getMetricsDiff({
                            current: normalize(metrics[field]),
                            past: normalize(pastMetrics[field]),
                            measure,
                            isReverse
                          })}
                        </Text>
                      </div>
                      <div>
                        <Text fontWeight="500" fontSize="24px">
                          <NumberFormat value={normalize(metrics[field])} displayType={'text'} thousandSeparator=" " />
                        </Text>
                        <Text fontWeight="500" fontSize="18px" color="#7E8B99">&nbsp;{measure}</Text>
                      </div>
                    </ChipItem>
                  </Grid>
                ))
              }
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <ReactMapGL
              mapLib={maplibregl}
              {...viewport}
              ref={mapRef}
              // dashboard heatmap fix
              onLoad={() => {
                const map = mapRef.current?.getMap()
                map.addSource('heatBikeSource', {
                  'type': 'geojson',
                  'data': `https://app.swappz.co/api/v1/dashboard/heatmap/?date_from=${dateForFilter.value.date_from}&date_to=${dateForFilter.value.date_to}&user_id=${currentUser?.id}`
                })
                map.addLayer(HeatBikesLayer, 'waterway')
              }}
              clickRadius={25}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              mapStyle="https://api.maptiler.com/maps/2fb3673c-6830-42d2-945f-e68babe1cdab/style.json?key=SvQLtzWmUvzNtKqVUcYM"
              onMove={(e) => {
                setViewport(e.viewState)
              }}
            >
              {modalBike?.id && <MapBikeModal dateFilter={dateForFilter} cliensFilter={autocompliteClients} plansFilter={autocomplitePlans} bike={modalBike} onClose={() => setModalBike(null)} />}
              {
                (!isHeatMap) && dashboardBikes?.length && dashboardBikes.filter(({ location }) => Boolean(location)).map(bike => (
                  <Marker
                    key={bike.id}
                    onClick={() => setModalBike(bike)}
                    latitude={bike?.location?.lat}
                    longitude={bike?.location?.lon}
                  >
                    <MapMarkerIcon
                      color={'red'}
                    />
                  </Marker>
                ))
              }
          
          </ReactMapGL>
        </Grid>
      </Grid>
    </PageWrapper>
  )
}

export default Dashboard