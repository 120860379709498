import React from 'react'

const NextPageIcon = () => {
    return (
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.806549 0.953126C1.0568 0.690956 1.47221 0.681295 1.73438 0.931549L6.54688 5.5253C6.6766 5.64913 6.75 5.82066 6.75 6C6.75 6.17934 6.6766 6.35087 6.54688 6.4747L1.73438 11.0685C1.47221 11.3187 1.0568 11.309 0.806549 11.0469C0.556295 10.7847 0.565956 10.3693 0.828126 10.119L5.14332 6L0.828126 1.88095C0.565956 1.6307 0.556295 1.2153 0.806549 0.953126Z" fill="#4A545E"/>
      </svg>




    )
}

export default NextPageIcon 