import React from 'react'

const LogoutIcon = () => {
    return (
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.8011 12.5087C20.0663 12.2214 20.0663 11.7786 19.8011 11.4913L16.8011 8.24129C16.5201 7.93692 16.0457 7.91794 15.7413 8.1989C15.4369 8.47985 15.4179 8.95434 15.6989 9.25871L17.537 11.25L10.75 11.25C10.3358 11.25 10 11.5858 10 12C10 12.4142 10.3358 12.75 10.75 12.75L17.537 12.75L15.6989 14.7413C15.4179 15.0457 15.4369 15.5201 15.7413 15.8011C16.0457 16.0821 16.5201 16.0631 16.8011 15.7587L19.8011 12.5087ZM12 20.25C12 19.8358 11.6642 19.5 11.25 19.5L6.75 19.5C6.05964 19.5 5.5 18.9404 5.5 18.25L5.5 5.75C5.5 5.05964 6.05964 4.5 6.75 4.5L11.25 4.5C11.6642 4.5 12 4.16421 12 3.75C12 3.33579 11.6642 3 11.25 3L6.75 3C5.23122 3 4 4.23122 4 5.75L4 18.25C4 19.7688 5.23122 21 6.75 21L11.25 21C11.6642 21 12 20.6642 12 20.25Z" fill="#C53434"/>
      </svg>

    )
}

export default LogoutIcon;