import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { FleetHeader, CommonTable } from '../../components'
import { fetchListUnits, fetchListUnitsCounts, setListItems } from '../../store/slices/fleet-list/slice'
import { getChargeStatus } from '../../core'
import { colorForFilters, ChargeIconByCharge, colorsForCharge } from '../../core/constants'
import {
  PageWrapper,
  StatusCellInner,
  ChargeCellInner,
  TableContainer,
  StatusTimer
} from './styled'

const FleetList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { 
    batteries: {
      items: bicyclesBatteries,
    },
    fleetList: { filterBy, selectedFilters, listItems: { items: listItems, page_size, page } }
   } = useSelector(state => state);

   const columns = [
    {
      headCell: t("fleetList.tableHeadCells.id"),
      getValue: (row) => row.uid
    },
    {
      headCell: t("fleetList.tableHeadCells.model"),
      getValue: (row) => row.model
    },
    {
      headCell: t("fleetList.tableHeadCells.subscription"),
      getValue: (row) => row.subscription
    },
    {
      headCell: t("fleetList.tableHeadCells.status"),
      getValue: (row) => 
        <StatusCellInner color={colorForFilters['status'][row.status]}>
          {t(`units.statuses.${row.status}`)}
          {row.status === 'OUT_OF_ORDER' && <StatusTimer>16 hrs left to fix</StatusTimer>}
        </StatusCellInner>
    },
    {
      headCell: t("fleetList.tableHeadCells.battery"),
      getValue: (row) => 
        <ChargeCellInner color={colorsForCharge[row.chargeStatus]}>
          {ChargeIconByCharge[row.chargeStatus]}
          {row?.chargeStatus !== "MISSING" ? `${row?.charge}%` : '—'}
        </ChargeCellInner>
    },
    // {
    //   headCell: t("fleetList.tableHeadCells.breakages"),
    //   getValue: (row) => row.breakages
    // },
    // {
    //   headCell: t("fleetList.tableHeadCells.cost"),
    //   getValue: (row) => `$${row.cost}`
    // },
  ]

  const tableData = useMemo(() => listItems.map(bike => ({
    chargeStatus: getChargeStatus(bike?.charge),
    charge: parseInt(bike?.charge),
    status: bike.status,
    subscription: t(`units.subscriptions.${bike.subscription_type}`),
    model: bike.bike_model.name,
    uid: bike.uid,
    id: bike.id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [listItems, bicyclesBatteries])
  
  useEffect(() => {
    dispatch(fetchListUnits({ filters:{
      [filterBy]: selectedFilters.join(','),
      page_size,
      page
    }}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, filterBy, page_size, page])

  useEffect(() => {
    dispatch(fetchListUnitsCounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy])
  return (
    <PageWrapper>
      <FleetHeader/>
      <TableContainer>
        <CommonTable
          pagination={{
            paginationSelector: state => state.fleetList.listItems,
            pagintaionSetter: props => dispatch(setListItems(props))
          }}
          columns={columns} data={tableData}
          onRowClick={(row) => navigate(`/unit/${row.id}/details`, { state: { backpath: '/fleet/list'}})}
        />
      </TableContainer>
    </PageWrapper>
  )
}

export default FleetList